import React from 'react'

function AssessmentDetails({ standardSearchResultInfo }) {
  if (!standardSearchResultInfo) return <div className="text-gray-600 text-sm p-4">No data available</div>

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' }
    return new Date(dateString).toLocaleDateString(undefined, options)
  }

  return (
    <div className="bg-white shadow-lg rounded-lg p-6 my-4">
      <h2 className="text-xl font-semibold mb-4">Assessment Details</h2>
      <div className="text-gray-700"><strong>Standard Code:</strong> {standardSearchResultInfo.standard_code}</div>
      <div className="text-gray-700"><strong>Discipline:</strong> {standardSearchResultInfo.discipline}</div>
      <div className="text-gray-700"><strong>Subdiscipline:</strong> {standardSearchResultInfo.subdiscipline}</div>
      <div className="text-gray-700"><strong>Grade:</strong> {standardSearchResultInfo.grade}</div>
      <div className="text-gray-700"><strong>Performance Expectation:</strong> {standardSearchResultInfo.pe}</div>
      <div className="text-gray-700"><strong>SEP:</strong> {standardSearchResultInfo.SEP}</div>
      <div className="text-gray-700"><strong>CCC:</strong> {standardSearchResultInfo.CCC}</div>
      <div className="text-gray-700"><strong>DCI:</strong> {standardSearchResultInfo.DCI}</div>
      <div className="text-gray-700"><strong>Assessment Boundary:</strong> {standardSearchResultInfo.boundary}</div>
      <div className="text-gray-700"><strong>Clarification:</strong> {standardSearchResultInfo.clarification || 'N/A'}</div>
      <div className="text-gray-700 mt-4">
        <strong>Features:</strong>
        <pre className="bg-gray-100 p-3 rounded mt-2 whitespace-pre-wrap">{standardSearchResultInfo.features}</pre>
      </div>
    </div>
  )
}

export default AssessmentDetails
