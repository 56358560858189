import React from 'react'

const Footer = ({ openPopup }) => {
  
  return (
    <footer className='text-white p-1 hover:bg-blue-900 cursor rounded'>
      <div className='container mx-auto text-center'>
        <p className='text-xs custom-shadow'>
          © 2023-2024 <a href="https://forgen.ai" className="underline hover:text-blue-300" target="_blank">ForGen AI</a>, LLC. All rights reserved. <button className='underline text-gray-500 hover:text-blue-300' onClick={openPopup}>Contact us</button>.
        </p>
      </div>
      <style jsx>{`
        .custom-shadow {
          text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.6);
        }
      `}</style>
    </footer>
  )
}

export default Footer
