import React, { useState } from 'react'
import { studentLogin } from './api'

function StudentForm({ onLogin }) {
  const [examId, setExamId] = useState('')
  const [studentId, setStudentId] = useState('')
  const [loginFailed, setLoginFailed] = useState(false)
  const [loginError, setLoginError] = useState(false)

  const handleExamIdChange = (e) => {
    setExamId(e.target.value)
  }

  const handleStudentIdChange = (e) => {
    setStudentId(e.target.value)
  }

  const handleStudentIdLogin = () => {
      studentLogin(examId.toUpperCase(), studentId.toUpperCase()).then((results) => {
         if (results && results.student_name) {
            localStorage.setItem('history',"")
            onLogin(results)
         }

      })
      .catch(error => {
          if (error.response && error.response.status === 403) {
            setLoginFailed(true)
            setLoginError(false)
          } else {
            setLoginError(true)
            setLoginFailed(false)
          }
      })
  }

  return (
    <div className='flex items-center justify-center w-64'>
      <div className='p-4 m-2 border rounded shadow bg-green-100'>
        <h2 className='text-2xl text-center font-bold mb-2'>Student Login</h2>
        <div className='flex flex-col space-y-2'>
          <input
            type='text'
            name='assessmentId'
            placeholder='Enter Exam Id'
            value={examId}
            onChange={handleExamIdChange}
            className='border rounded p-2'
          />
          <input
            type='text'
            name='studentId'
            placeholder='Enter Student Id'
            value={studentId}
            onChange={handleStudentIdChange}
            className='border rounded p-2'
          />
          <button type='submit' onClick={handleStudentIdLogin}  className='bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded'>
            Go to Exam
          </button>
        </div>
        {loginFailed && (
          <div className="text-red-500 text-sm bg-red-100 p-2 rounded mb-2">
            Exam Id or Student Id incorrect!
          </div>
        )}
        {loginError && (
          <div className="text-red-500 text-sm bg-red-100 p-2 rounded mb-2">
            Error. If errors persist, contact info@forgen.ai
          </div>
        )}
      </div>
    </div>
  )
}

export default StudentForm
