import React, { useEffect, useState } from 'react'
import { getStudentInfo } from './api'
import RedX from './RedX'

function StudentItem({ studentId, studentName, deleteStudentByStudentId }) {
    const [studentEmail, setStudentEmail] = useState("")
    const [deleteStudentVisible, setDeleteStudentVisible] = useState(false)


    if (!studentId){
      return null
    }

    const showDeleteStudent = () => {
      setDeleteStudentVisible(true)
    }

    const hideDeleteStudent = () => {
      setDeleteStudentVisible(false)
    }

    async function deleteStudent(){
        deleteStudentByStudentId(studentId)
        setDeleteStudentVisible(false)
    }

    return (
    <div className="flex justify-between items-center bg-white shadow-md px-4 py-2 rounded-lg">
        {/* Render your class information here */}
        <div className="text-sm text-gray-700 font-medium">{studentName}</div>
        <div className="text-sm text-gray-600">{studentId}</div>
        <div className="text-sm text-blue-500 underline">{studentEmail}</div>
        <button
          onClick={showDeleteStudent}
          className="bg-white p-2 rounded-full hover:shadow-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          aria-label="Delete Student"
        >
          <RedX />
        </button>
        {deleteStudentVisible && (
                  <div className="fixed z-10 inset-0 overflow-y-auto">
                      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                          <div className="fixed inset-0 transition-opacity">
                              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                          </div>
                          <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                              <div>
                                  <div className="text-lg leading-6 font-medium text-gray-900 mb-4">
                                      Delete Student
                                  </div>
                                  <div className="w-full items-stretch">
                                    <div className="inline" >Are you sure you want to delete {studentName}?</div>
                                  </div>
                              </div>
                              <div className="mt-5 sm:mt-6">
                                  <span className="flex w-full rounded-md shadow-sm">
                                      <button onClick={deleteStudent} type="button" className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-pink-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                                          Confirm Delete {studentName}
                                      </button>
                                      <button onClick={hideDeleteStudent} type="button" className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-blue-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                                          Cancel
                                      </button>
                                  </span>
                              </div>
                          </div>
                      </div>
                  </div>
              )}
    </div>
    )
}

export default StudentItem
