import React, { useEffect, useState } from 'react'
import { addNewClass, getExamClassInfo } from './api'
import ClassItem from './ClassItem'

function ClassScreen({ classIds, addClassIdToClassIds }) {
    const [addClassVisible, setAddClassVisible] = useState(false)
    const [newClassName, setNewClassName] = useState("")
    const [newClassGrade, setNewClassGrade] = useState("")
    const [examsForClasses, setExamsForClasses] = useState({})

    useEffect(() => {
      getExamClassInfo().then(response => {
        const examClassInfo = response
        setExamsForClasses(examClassInfo)
      })
      .catch(error => console.error('Error: ', error))
    }, [])

    const showAddNewClass = () => {
      setAddClassVisible(true)
    }

    const cancelAddNewClass = () => {
      setAddClassVisible(false)
    }


    async function addNewClassForTeacher(){
        const classData = {
            class_name: newClassName,
            class_grade: newClassGrade,
        }
        const addNewClassResponse = await addNewClass(classData)
        if (addNewClassResponse && addNewClassResponse["class_id"]) {
            console.log("Class added successfully!")
            addClassIdToClassIds(addNewClassResponse["class_id"])
        }
        setAddClassVisible(false)
    }

    const removeDeletedClass = (classIdToBeDeleted) => {
        const index = classIds.indexOf(classIdToBeDeleted);
        if (index > -1) {
            classIds.splice(index, 1);
        }
    }

    return (
        <div className='flex'>
          <div className='mr-4 p-4 w-72 bg-white rounded-lg shadow-md max-h-[calc(100vh-50px)] rounded items-center text-center'>
            <div className='flex-col relative flex-col ml-2 rounded text-sm'>
              <div className="top-1 text-xl font-bold text-black w-full mb-3">Class Portal</div>
              <div className="overflow-y-auto max-h-[calc(100vh-150px)]">
                {classIds && classIds.map(id =>
                  <ClassItem key={id} classId={id} deleteClassCallback={removeDeletedClass} examsForClass={examsForClasses[id]} />
                )}
              </div>
              <button onClick={showAddNewClass} type="button" className="bottom-1 inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-blue-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">Add New Class</button>
              {addClassVisible && (
                    <div className="fixed z-10 inset-0 overflow-y-auto">
                        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                            <div className="fixed inset-0 transition-opacity">
                                <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                            </div>
                            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                                <div>
                                    <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4 w-full text-center">
                                        Add New Class
                                    </h3>
                                    <div className="w-full items-stretch">
                                      <div className="inline" >Class Name: </div>
                                      <input
                                          type="text"
                                          value={newClassName}
                                          onChange={(e) => setNewClassName(e.target.value)}
                                          className="border rounded-md p-1 m-1 inline"
                                          placeholder="Enter class name"
                                      />
                                    </div>
                                    <div className="w-full items-stretch">
                                      <div className="inline" >Grade: </div>
                                      <input
                                          type="text"
                                          value={newClassGrade}
                                          onChange={(e) => setNewClassGrade(e.target.value)}
                                          className="border rounded-md p-1 m-1 inline"
                                          placeholder="Enter class grade"
                                      />
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-6">
                                    <span className="flex w-full rounded-md shadow-sm">
                                        <button onClick={addNewClassForTeacher} type="button" className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-blue-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                                            Add Class
                                        </button>
                                        <button onClick={cancelAddNewClass} type="button" className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-pink-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                                            Cancel
                                        </button>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
          </div>
        </div>
    )
}

export default ClassScreen
