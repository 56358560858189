import React, { useState } from 'react'
import axios from 'axios'
import { callSignUp } from './api'

function SignupForm({ onSignupSuccess }) {
  const [showErrorText, setShowErrorText] = useState(false)
  const [errorText, setErrorText] = useState("")
  const [signupData, setSignupData] = useState({
    username: '',
    email: '',
    password: ''
  })

  const handleSignupChange = (e) => {
    const { name, value } = e.target
    setSignupData((prevState) => ({ ...prevState, [name]: value }))
  }

  const handleSignupSubmit = (e) => {
    e.preventDefault()
    setShowErrorText(false)
    // Perform signup request using Axios
    callSignUp(signupData)
      .then((response) => {
        console.log(response.data)
        onSignupSuccess(signupData.username)
      })
      .catch((error) => {
        console.error('Error signing up:', error)
        setErrorText("Username exists. If you believe this is a mistake, please contact info@forgen.ai")
        setShowErrorText(true)
      })
  }

  return (
    <div className='flex items-center justify-center w-64'>
      <div className='p-4 m-2 border rounded shadow bg-blue-100'>
        <h2 className='text-2xl text-center font-bold mb-2'>Sign Up</h2>
        <form onSubmit={handleSignupSubmit} className='flex flex-col space-y-2'>
          <input
            type='text'
            name='username'
            placeholder='Username'
            value={signupData.username}
            onChange={handleSignupChange}
            className='border rounded p-2'
          />
          <input
            type='email'
            name='email'
            placeholder='Email'
            value={signupData.email}
            onChange={handleSignupChange}
            className='border rounded p-2'
          />
          <input
            type='password'
            name='password'
            placeholder='Password'
            value={signupData.password}
            onChange={handleSignupChange}
            className='border rounded p-2'
          />
          <button type='submit' className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'>
            Sign Up
          </button>
        </form>
        <div
          onClick={() => onSignupSuccess()}
          className="text-xs text-center bg-gray-200 hover:bg-gray-300 text-gray-700 px-4 py-2 rounded-md transition duration-300 ease-in-out cursor-pointer"
        >
          already signed up?
          <br />
          click here to enter confirmation code
        </div>
        {showErrorText && (
          <div className="text-red-500 text-sm bg-red-100 p-2 rounded mb-2">
            {errorText}
          </div>
        )}
      </div>
    </div>
  )
}

export default SignupForm
