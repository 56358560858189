import React from 'react'

function TermMap({ termMap, onClose }) {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-5 rounded-md w-1/2 h-1/2 overflow-y-auto">
        <button onClick={onClose} className="bg-red-500 text-white p-2 rounded-full float-right hover:bg-red-600">X</button>
        
        {termMap.map((term, index) => (
          <div key={index} className="p-3 mb-4">
            <h3 className="text-lg font-semibold text-gray-700">{term.term_name}</h3>
            <p className="text-gray-600"><strong>Publication ID:</strong> {term.publication_id}</p>
            <p className="text-gray-600"><strong>Term ID:</strong> {term.term_id}</p>
            
            <p className="text-gray-600"><strong>Characterizations:</strong></p>
            <ul>
              {term.characterizations.replace(/\[|\]/g, '').split("',").map((charact, i) => {
                const [claims, ...rest] = charact.split(":")
                const content = rest.join(":")
                
                let [text, ...paragraphs] = content.split("(")
                text = text.substring(1)

                paragraphs = paragraphs.join(', ').replace(/\)/g, '').replace(/, $/, '')
                
                return (
                  <li key={i} className="mb-2">
                    <p>{i + 1}. {text}</p>
                    <p className="ml-4">Claims: {claims.split(',').join(', ').replace("'","")}</p>
                    {paragraphs != "" && <p className="ml-4">Paragraphs: {paragraphs.replaceAll(" , ",", ")}</p>}
                  </li>
                )
              })}
            </ul>
          </div>
        ))}
      </div>
    </div>
  )
}

export default TermMap
