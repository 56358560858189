import axios from 'axios'
import config from './config'
import { Storage } from 'aws-amplify'
import { loadStripe } from '@stripe/stripe-js'


let BASE_URL = null


if (process.env.NODE_ENV === 'development') {
  BASE_URL = config.BASE_URL
} else {
  BASE_URL = config.PROD_URL
}


let ACCESS_OBJ = {}, storedHistory


let stripe = await loadStripe('pk_live_51HmQQxJLxKssJyu66slQWOnqolbwqzUeDxHabUfYXLylNdSUwDF3exVA4c7uN5EepIrwKPY599CDdPkZjIkIZ3JN00w35T2T3Q')
if (process.env.NODE_ENV === 'development') {
  stripe = await loadStripe('pk_test_51HmQQxJLxKssJyu6vtlQ7aN9uaEiEhLP6HJtDsbWXxYDbhpkvdgu02xGIuM4SVQ48qrGQVFK55ZujfFK4AGP1xG700XQVYcZq6')
}


const callSignUp = async(signUpData) => {
  const response = await axios.post(`${BASE_URL}/user/signup`, signUpData)
  return response

}

const userLogin = async(loginData) => {
   const response = await axios.post(`${BASE_URL}/user/login`, loginData)
   ACCESS_OBJ = response.data
   if (ACCESS_OBJ["id_token"]){
      localStorage.setItem('id_token', ACCESS_OBJ["id_token"])
   }
   if (ACCESS_OBJ["refresh_token"]){
      localStorage.setItem('refresh_token', ACCESS_OBJ["refresh_token"])
   }
   if (ACCESS_OBJ["access_token"]){
      localStorage.setItem('access_token', ACCESS_OBJ["access_token"])
   }
   if (ACCESS_OBJ["username"]){
      localStorage.setItem('username', ACCESS_OBJ["username"])
   }
   localStorage.setItem('chat_context', "")
   return response.data
}


const confirmSignup = async(confirmationData) => {
   const response = await axios.post(`${BASE_URL}/user/confirm_signup`, confirmationData)
   return response.data
}


const resendCode = async(username) => {
   const response = await axios.post(`${BASE_URL}/user/resend_code`, {'username': username})
   return response.data
}


const getPatentInfo = async(applicationNumber) => {
   const response = await axios.post(`${BASE_URL}/chat/patent_info`, {'application_num':applicationNumber})
   return response.data
}


const refreshToken = async() => {
   const refreshToken = localStorage.getItem('refresh_token')
   const username = localStorage.getItem('username')
   const response = await axios.post(`${BASE_URL}/user/refresh_token`, {'refresh_token': refreshToken, 'username': username})
   return response.data
}


const checkLogin = async() => {
   const idToken = localStorage.getItem('id_token')
   const refreshToken = localStorage.getItem('refresh_token')
   const username = localStorage.getItem('username')
   const isStudent = localStorage.getItem('is_student')
   if (isStudent == "true"){
      return ""
   }
   const response = await axios.post(`${BASE_URL}/user/check_login`, {'id_token': idToken, 'refresh_token': refreshToken, 'username': username})
   ACCESS_OBJ = response.data
   if (ACCESS_OBJ["id_token"]){
      localStorage.setItem('id_token', ACCESS_OBJ["id_token"])
   }
   if (ACCESS_OBJ["refresh_token"]){
      localStorage.setItem('refresh_token', ACCESS_OBJ["refresh_token"])
   }
   if (ACCESS_OBJ["access_token"]){
      localStorage.setItem('access_token', ACCESS_OBJ["access_token"])
   }
   if (ACCESS_OBJ["username"]){
      localStorage.setItem('username', ACCESS_OBJ["username"])
   }
   return response.data
}


const logout = async(delete_storage) => {
   const access_token = localStorage.getItem('access_token')
   if (delete_storage) {
     localStorage.setItem('id_token', "")
     localStorage.setItem('refresh_token', "")
     localStorage.setItem('access_token', "")
     localStorage.setItem('username', "")
     localStorage.setItem('chat_context', "")
     localStorage.setItem('patents', "")
     localStorage.setItem('history', "")
   }
   const response = await axios.post(`${BASE_URL}/user/logout`, {'access_token': access_token})
   window.location.reload()
   return response.data
}

const getPatents = () => {
    try {
        const patents = localStorage.getItem('patents')
        return JSON.parse(patents)
    } catch (error) {
        console.log("error: " + error)
        return {}
    }
}

const getContext = () => {
    try {
        const context = localStorage.getItem('chat_context')
        return JSON.parse(context)
//        return context
    } catch (error) {
        console.log("error: " + error)
        return {}
    }
}

const runCustomAnalysis = async(selectedNumber, resourceType, text) => {
    try {
        const response = await axios.post(`${BASE_URL}/chat/custom_analysis`, {'selected_number': selectedNumber, 'resource_type': resourceType, 'user_prompt': text} , {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        return response.data
    }
   catch(e){

   }
}

const getCustomAnalysisResult = async(jobId) => {
    try {
        const response = await axios.post(`${BASE_URL}/chat/get_analysis`, {"job_id": jobId}, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        return response.data
    }
    catch(e){
    }
}

const getScores = async(examId) => {
    try {
        const idToken = localStorage.getItem('id_token')
        const response = await axios.post(`${BASE_URL}/teacher/get_scores`, {'id_token': idToken, 'exam_id': examId} , {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        return response.data
    } catch (error) {
        if (error.response && error.response.status === 403) {
//            logout(false)
        }
        throw error
    }
}

const generateFeedback = async(examId, studentId, messages) => {
    try {
        const response = await axios.post(`${BASE_URL}/student/feedback_report`, {'messages': messages, 'exam_id': examId, 'student_id': studentId} , {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        return response.data
    } catch (error) {
        if (error.response && error.response.status === 403) {
//            logout(false)
        }
        throw error
    }
}


const getFeedbackReport = async(examId, studentId) => {
    try {
        const response = await axios.post(`${BASE_URL}/student/get_feedback_report`, {'exam_id': examId, 'student_id': studentId} , {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        return response.data
    } catch (error) {
        if (error.response && error.response.status === 403) {
//            logout(false)
        }
        throw error
    }
}


const gradeExam = async(examId, studentId, assessmentId, messages) => {
    try {
        const response = await axios.post(`${BASE_URL}/student/grade_exam`, {'messages': messages, 'exam_id': examId, 'student_id': studentId, 'assessment_id': assessmentId} , {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        return response.data
    } catch (error) {
        if (error.response && error.response.status === 403) {
//            logout(false)
        }
        throw error
    }
}


const sendMessage = async(message, history, studentId) => {
    try {
        localStorage.setItem('history', JSON.stringify([...history, {"role":"user","content":message}]))
        const response = await axios.post(`${BASE_URL}/chat/message`, {'message': message, 'history': history, 'student_id': studentId} , {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        if (response && response.data && response.data['history'])
            localStorage.setItem('history', JSON.stringify(response.data['history']))
        return response.data
    } catch (error) {
        if (error.response && error.response.status === 403) {
//            logout(false)
        }
        throw error
    }
}


const continueMessage = async(message, history, studentId) => {
    try {
        localStorage.setItem('history', JSON.stringify([...history, {"role":"user","content":message}]))
        const response = await axios.post(`${BASE_URL}/chat/continue_message`, {'message': message, 'history': history, 'student_id': studentId} , {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        if (response && response.data && response.data['history'])
            localStorage.setItem('history', JSON.stringify(response.data['history']))
        return response.data
    } catch (error) {
        if (error.response && error.response.status === 403) {
//            logout(false)
        }
        throw error
    }
}

const getMessage= async(messageId) => {
   const response = await axios.post(`${BASE_URL}/chat/get_message`, {"message_id": messageId}, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
   if (response && response.data && response.data['message']) {
       storedHistory = localStorage.getItem('history', "")
       if (storedHistory && storedHistory != "") {
           localStorage.setItem('history', JSON.stringify([...JSON.parse(storedHistory), {"role":"assistant","content":response.data['message']}]))
       }
   }
   return response.data
}


const getTermMap = async(patentNumber, applicationNumber, resourceType) => {
   const response = await axios.post(`${BASE_URL}/chat/term_map`, {"patent_number": patentNumber, "application_number": applicationNumber, "resource_type": resourceType}, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
   return response.data
}


const deepClaimTermReview = async(patentNumber, applicationNumber, resourceType) => {
   const response = await axios.post(`${BASE_URL}/chat/deep_claim_term_review`, {"patent_number": patentNumber, "application_number": applicationNumber, "resource_type": resourceType}, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
   return response.data
}


const performRag = async (query, useVicuna, clusterId) => {
  const response = await axios.post(`${BASE_URL}/chat/perform_rag`, { query, useVicuna, clusterId })
  return response.data
}


const performHybridSearch = async (query, clusterId) => {
  const response = await axios.post(`${BASE_URL}/chat/perform_hybrid_search`, { query, clusterId })
  return response.data
}


const getJobStatus = async (query) => {
  const response = await axios.post(`${BASE_URL}/knowledge/job_status`, { query })
  return response.data
}


const extractKnowledgeFromFile = async (query) => {
  try {
    const response = await axios.post(`${BASE_URL}/knowledge/extract_knowledge_from_file`, query, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return response.data
  } catch (error) {
    console.error('Error uploading file: ', error)
  }
  return ''
}


const extractKnowledgeFromUrl = async (query) => {
  const response = await axios.post(`${BASE_URL}/knowledge/extract_knowledge_from_url`, { query })
  return response.data
}


const deleteEmbeddings = async () => {
  const response = await axios.post(`${BASE_URL}/knowledge/delete_embeddings`, {})
  return response.data
}


const fetchClusters = async () => {
  const response = await axios.get(`${BASE_URL}/knowledge/fetch_clusters`)
  return response.data
}


const addCluster = async (cluster) => {
  const response = await axios.post(`${BASE_URL}/knowledge/add_cluster`, { cluster })
  return response.data
}


const deleteCluster = async (cluster_id) => {
  const response = await axios.post(`${BASE_URL}/knowledge/delete_cluster`, { cluster_id })
  return response.data
}

const saveChat = async (chatData) => {
    try {
        const idToken = localStorage.getItem('id_token')
        chatData['id_token'] = idToken
        chatData['chat_context'] = getContext()
        chatData['patents'] = getPatents()
        const response = await axios.post(`${BASE_URL}/chat/save_chat`, chatData, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
        return response.data
    } catch (error) {
        console.error("Error adding new chat:", error)
    }
}

const uploadFiles = async (query) => {
  try {
    const idToken = localStorage.getItem('id_token')
    query.append('id_token', idToken)
    const response = await axios.post(`${BASE_URL}/chat/upload_files`, query, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return response.data
  } catch (error) {
    console.error('Error uploading file: ', error)
  }
  return ''
}

const addNewChat = async (chatData) => {
    try {
        const idToken = localStorage.getItem('id_token')
        chatData['id_token'] = idToken
        chatData['chat_context'] = getContext()
        chatData['patents'] = getPatents()
        const response = await axios.post(`${BASE_URL}/chat/add_chat`, chatData, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
        return response.data
    } catch (error) {
        console.error("Error adding new chat:", error)
    }
}

const fetchChats = async () => {
  try {
    const idToken = localStorage.getItem('id_token')
    const response = await axios.post(`${BASE_URL}/chat/get_all_chats`, {'id_token': idToken})
    return response.data
  } catch (error) {
    console.error("Failed to fetch chats:", error)
    throw error
  }
}

const deleteExistingChat = async (chat_id) => {
    try {
        const idToken = localStorage.getItem('id_token')
        const response = await axios.post(`${BASE_URL}/chat/delete_chat`, {'id_token': idToken, 'chat_id': chat_id})
        return response.data
    } catch (error) {
        console.error("Failed to delete chat:" + chat_id, error)
    throw error
  }
}

const goToPaymentPage = async (username) => {
    try {
        const response = await axios.post(`${BASE_URL}/stripe/create-checkout-session`, {'username': username})
        const sessionId = response.data.id
        const { error } = await stripe.redirectToCheckout({ sessionId })
        if (error) {
          console.error(error)
        }
    } catch (error) {
        console.error("Failed to get payment session", error)
    throw error
  }
}

const setPaymentSuccessful = async (pKey) => {
    try {
        const response = await axios.post(`${BASE_URL}/stripe/set-payment-successful`, {'payment_key': pKey})
        return response.data
    } catch (error) {
        console.error("Failed to get payment session", error)
    throw error
  }
}

const searchAssessmentCode = async(standard_code) => {
    try {
        const idToken = localStorage.getItem('id_token')
        const response = await axios.post(`${BASE_URL}/teacher/search_standard_code`, {'standard_code': standard_code.toUpperCase(), 'id_token': idToken} , {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        return response.data
    } catch (error) {
        if (error.response && error.response.status === 403) {
            logout(false)
        }
        throw error
    }
}


const getClassInfo = async(class_id) => {
    try {
        const idToken = localStorage.getItem('id_token')
        const response = await axios.post(`${BASE_URL}/teacher/class_info`, {'class_id': class_id, 'id_token': idToken} , {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        return response.data
    } catch (error) {
        if (error.response && error.response.status === 403) {
            logout(false)
        }
        throw error
    }
}

const getExamInfo = async(exam_id) => {
    try {
        const idToken = localStorage.getItem('id_token')
        const response = await axios.post(`${BASE_URL}/teacher/exam_info`, {'exam_id': exam_id, 'id_token': idToken} , {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        return response.data
    } catch (error) {
        if (error.response && error.response.status === 403) {
            logout(false)
        }
        throw error
    }
}

const getExamInfoForStudent = async(exam_id, student_id) => {
    try {
        const idToken = localStorage.getItem('id_token')
        const response = await axios.post(`${BASE_URL}/student/exam_info_for_student`, {'exam_id': exam_id, 'student_id': student_id, 'id_token': idToken} , {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        return response.data
    } catch (error) {
        if (error.response && error.response.status === 403) {
            logout(false)
        }
        throw error
    }
}

const getExamClassInfo = async() => {
    try {
        const idToken = localStorage.getItem('id_token')
        const response = await axios.post(`${BASE_URL}/teacher/get_exam_class_info`, {'id_token': idToken} , {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        return response.data
    } catch (error) {
        if (error.response && error.response.status === 403) {
            logout(false)
        }
        throw error
    }
}

const getStudentInfo = async(student_id) => {
    try {
        const idToken = localStorage.getItem('id_token')
        const response = await axios.post(`${BASE_URL}/teacher/student_info`, {'student_id': student_id, 'id_token': idToken} , {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        return response.data
    } catch (error) {
        if (error.response && error.response.status === 403) {
            logout(false)
        }
        throw error
    }
}

const getAssessmentInfo = async(assessment_id) => {
    try {
        const idToken = localStorage.getItem('id_token')
        const response = await axios.post(`${BASE_URL}/teacher/assessment_info`, {'assessment_id': assessment_id, 'id_token': idToken} , {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        return response.data
    } catch (error) {
        if (error.response && error.response.status === 403) {
            logout(false)
        }
        throw error
    }
}


const addNewStudentToClass = async(class_id, studentData) => {
    try {
        const idToken = localStorage.getItem('id_token')
        const response = await axios.post(`${BASE_URL}/teacher/add_student_to_class`, {'class_id': class_id, 'student_name': studentData["student_name"], 'student_email': studentData["student_email"], 'id_token': idToken} , {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        return response.data
    } catch (error) {
        if (error.response && error.response.status === 403) {
            logout(false)
        }
        throw error
    }
}

const addNewAssessmentToExam = async(exam_id, assessmentData) => {
    try {
        const idToken = localStorage.getItem('id_token')
        const response = await axios.post(`${BASE_URL}/teacher/add_assessment_to_exam`, {'exam_id': exam_id, 'assessment_text': assessmentData["assessment_text"], 'standard_code': assessmentData["standard_code"].toUpperCase(), 'id_token': idToken} , {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        return response.data
    } catch (error) {
        if (error.response && error.response.status === 403) {
            logout(false)
        }
        throw error
    }
}

const addNewTest = async(examData) => {
    try {
        const idToken = localStorage.getItem('id_token')
        const response = await axios.post(`${BASE_URL}/teacher/add_exam`, {'exam_name': examData["exam_name"], 'id_token': idToken} , {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        return response.data
    } catch (error) {
        if (error.response && error.response.status === 403) {
            logout(false)
        }
        throw error
    }
}

const addNewClass = async(classData) => {
    try {
        const idToken = localStorage.getItem('id_token')
        const response = await axios.post(`${BASE_URL}/teacher/add_class`, {'class_name': classData["class_name"], 'class_grade': classData["class_grade"], 'id_token': idToken} , {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        return response.data
    } catch (error) {
        if (error.response && error.response.status === 403) {
            logout(false)
        }
        throw error
    }
}

const deleteClassByClassId = async(classId) => {
    try {
        const idToken = localStorage.getItem('id_token')
        const response = await axios.post(`${BASE_URL}/teacher/delete_class`, {'class_id': classId, 'id_token': idToken} , {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        return response.data
    } catch (error) {
        if (error.response && error.response.status === 403) {
            logout(false)
        }
        throw error
    }
}

const deleteExamByExamId = async(examId) => {
    try {
        const idToken = localStorage.getItem('id_token')
        const response = await axios.post(`${BASE_URL}/teacher/delete_exam`, {'exam_id': examId, 'id_token': idToken} , {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        return response.data
    } catch (error) {
        if (error.response && error.response.status === 403) {
            logout(false)
        }
        throw error
    }
}

const deleteAssessmentByIds = async(examId, assessmentId) => {
    try {
        const idToken = localStorage.getItem('id_token')
        const response = await axios.post(`${BASE_URL}/teacher/delete_assessment`, {'exam_id': examId, 'assessment_id': assessmentId, 'id_token': idToken} , {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        return response.data
    } catch (error) {
        if (error.response && error.response.status === 403) {
            logout(false)
        }
        throw error
    }
}

const deleteStudentByClassAndStudentId = async(classId, studentId) => {
    try {
        const idToken = localStorage.getItem('id_token')
        const response = await axios.post(`${BASE_URL}/teacher/delete_student`, {'class_id': classId, 'student_id': studentId, 'id_token': idToken} , {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        return response.data
    } catch (error) {
        if (error.response && error.response.status === 403) {
            logout(false)
        }
        throw error
    }
}

const assignTestToClass = async(examId, classId) => {
    try {
        const idToken = localStorage.getItem('id_token')
        const response = await axios.post(`${BASE_URL}/teacher/assign_exam`, {'exam_id': examId, 'class_id': classId, 'id_token': idToken} , {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        return response.data
    } catch (error) {
        if (error.response && error.response.status === 403) {
            logout(false)
        }
        throw error
    }
}

const generateAnExample = async(assessmentData) => {
    try {
        const idToken = localStorage.getItem('id_token')
        const response = await axios.post(`${BASE_URL}/teacher/gen_example`, {'assessment_data': assessmentData, 'id_token': idToken} , {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        return response.data
    } catch (error) {
        if (error.response && error.response.status === 403) {
            logout(false)
        }
        throw error
    }
}


const studentLogin = async(exam_id, student_id) => {
    try {
        const idToken = localStorage.getItem('id_token')
        const response = await axios.post(`${BASE_URL}/student/login`, {'exam_id': exam_id, 'student_id': student_id, 'id_token': idToken} , {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        localStorage.setItem('is_student', "true")
        return response.data
    } catch (error) {
        if (error.response && error.response.status === 403) {
            logout(false)
        }
        throw error
    }
}

const startAssessment = async(assessment_id) => {
    try {
        const idToken = localStorage.getItem('id_token')
        const response = await axios.post(`${BASE_URL}/student/begin_assessment`, {'assessment_id': assessment_id, 'id_token': idToken} , {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        localStorage.setItem('is_student', "true")
        return response.data
    } catch (error) {
        if (error.response && error.response.status === 403) {
            logout(false)
        }
        throw error
    }
}

export {
  BASE_URL,
  callSignUp,
  userLogin,
  checkLogin,
  refreshToken,
  logout,
  runCustomAnalysis,
  getCustomAnalysisResult,
  confirmSignup,
  resendCode,
  getPatentInfo,
  sendMessage,
  continueMessage,
  getMessage,
  getTermMap,
  deepClaimTermReview,
  performRag,
  performHybridSearch,
  getJobStatus,
  extractKnowledgeFromFile,
  extractKnowledgeFromUrl,
  deleteEmbeddings,
  fetchClusters,
  addCluster,
  deleteCluster,
  addNewChat,
  saveChat,
  uploadFiles,
  fetchChats,
  deleteExistingChat,
  goToPaymentPage,
  setPaymentSuccessful,

  getClassInfo,
  getStudentInfo,
  getExamInfo,
  getExamInfoForStudent,
  getExamClassInfo,
  getAssessmentInfo,
  addNewStudentToClass,
  addNewAssessmentToExam,
  addNewClass,
  addNewTest,
  deleteClassByClassId,
  deleteExamByExamId,
  deleteStudentByClassAndStudentId,
  deleteAssessmentByIds,
  searchAssessmentCode,
  assignTestToClass,

  studentLogin,
  startAssessment,
  gradeExam,
  getScores,
  generateAnExample,
  generateFeedback,
  getFeedbackReport,
}
