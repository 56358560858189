import React from "react"

const TypingDots = () => {
  return (
    <div className="flex space-x-2">
      <div className="h-2 w-2 bg-gray-400 rounded-full animate-pulse"></div>
      <div className="h-2 w-2 bg-gray-400 rounded-full animate-pulse delay-150"></div>
      <div className="h-2 w-2 bg-gray-400 rounded-full animate-pulse delay-300"></div>
    </div>
  )
}

export default TypingDots
