import React, { useState } from 'react'
import { getTermMap, deepClaimTermReview } from './api'
import styles from './ColorCycle.module.css'
import ResourceProcessingStatus from './ResourceProcessingStatus'
import CustomAnalysis from './CustomAnalysis'
import TermMap from './TermMap'


  const mapping = {
      "applId": "Application Identifier",
      "appFilingDate": "Application Filing Date",
      "appEarlyPubDate": "Early Publication Date",
      "appEarlyPubNumber": "Early Publication Number",
      "appEntityStatus": "Application Entity Status",
      "appExamName": "Patent Examiner Name",
      "appGrpArtNumber": "Group Art Number",
      "appLocation": "Application Location",
      "appStatus": "Application Status",
      "appType": "Application Type",
      "applicants": "Applicants",
      "assignments": "Assignments",
      "attrnyAddr": "Attorney Address",
      "firstInventorFile": "First Inventor to File",
      "id": "Unique Identifier",
      "inventorName": "Inventor Name",
      "inventors": "Inventors",
      "lastUpdatedTimestamp": "Last Updated Timestamp",
      "parentContinuity": "Parent Continuity",
      "patentIssueDate": "Patent Issue Date",
      "patentNumber": "Patent Number",
      "patentTitle": "Patent Title"
  }
function PatentInfoComponent({ patentInfo, patentJobId, pubJobId, onRemove, setChatMessage, updateJobId }) {
    const [resource, setResource] = useState({})
    const [getDeepDivePatentTerms, setGetDeepDivePatentTerms] = useState(false)
    const [getDeepDivePublicationTerms, setGetDeepDivePublicationTerms] = useState(false)
    const [showCustomAnalysis, setShowCustomAnalysis] = useState(false)
    const [patInfo, setPatInfo] = useState({})
    const [isPatTermMapVisible, setIsPatTermMapVisible] = useState(false)
    const [patTermMapData, setPatTermMapData] = useState({})
    const [showPatTermMapButton, setShowPatTermMapButton] = useState(false)
    const [isPubTermMapVisible, setIsPubTermMapVisible] = useState(false)
    const [pubTermMapData, setPubTermMapData] = useState({})
    const [showPubTermMapButton, setShowPubTermMapButton] = useState(false)

  function lookupKey(rawKey) {

    return mapping[rawKey] || rawKey  // returns the friendly name if available, else returns the raw key
  }

function renderValue(value) {
    if (Array.isArray(value) || (typeof value === 'object' && value !== null)) {
        return JSON.stringify(value)
    }
    return value || 'N/A'
}
function addSlashAndComma(num) {
    const str = num.toString()

    // Insert slash after the second character and comma after the fifth character
    return `${str.substring(0, 2)}/${str.substring(2, 5)},${str.substring(5)}`
}

function extractPatentComponents(patentStr) {
    const regex = /^([A-Z]{2})(\d+)([A-Z]\d)$/
    const match = patentStr.match(regex)

    if (match) {
        return {
            countryCode: match[1],
            number: match[2],
            kindCode: match[3]
        }
    } else {
        return null
    }
}

const deepDivePatentTerms = (patentNumber, applicationNumber, resourceType) => {
  if (resourceType == "patent") {
    setGetDeepDivePatentTerms(true)
    setGetDeepDivePublicationTerms(false)
  } else if (resourceType == "publication"){
    setGetDeepDivePatentTerms(false)
    setGetDeepDivePublicationTerms(true)
  }
  deepClaimTermReview(patentNumber, applicationNumber, resourceType).then((response) => {
    setResource(response)
    var updatedPatent = patentInfo
    if (resourceType === "patent" && response.jobId && response.jobId != "") {
//      updatedPatent.patentJobId = response.jobId
      updateJobId(updatedPatent["Application Identifier"], response.jobId, "")
    }
    if (resourceType === "publication" && response.jobId && response.jobId != "") {
//      updatedPatent.pubJobId = response.jobId
      updateJobId(updatedPatent["Application Identifier"], "", response.jobId)

    }
//    setPatInfo(patInfo)
//    setResourceCallback(response)
    let context = JSON.parse(localStorage.getItem("chat_context"))
    context.last_context_type = resourceType
    context.last_context_number = extractPatentComponents(patentNumber)['number']
    localStorage.setItem("chat_context", JSON.stringify(context))
  }).catch((response) => {
      console.log("")
  })
}

  async function resourceComplete(status, resourceType, resourcePubNo){
  //  setGetDeepDivePatentTerms(true)
    setChatMessage("I now know information about the description and claims of " + resourceType + " " + resourcePubNo + ".")
    const response = await getTermMap(resourcePubNo, patentInfo["Application Identifier"], resourceType)
    if (resourceType ==  "patent") {
      setShowPatTermMapButton(true)
      setPatTermMapData(response.term_map)
    } else if (resourceType == "publication") {
      setShowPubTermMapButton(true)
      setPubTermMapData(response.term_map)
    }
  }
  return (
    <div className='mb-2 mr-4 p-4 w-72 bg-white rounded-lg shadow-md '>
      <div className='flex-col relative'>
        <button
          onClick={() => onRemove(patentInfo["Application Identifier"])}
          className="absolute top-0 right-2 bg-white p-2 rounded-full hover:shadow-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          aria-label="Close"
        >
          <svg
            className="w-4 h-4"
            fill="none"
            stroke="red"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            ></path>
          </svg>
        </button>
        <h2 className='text-xl mb-2 font-bold'>{addSlashAndComma(patentInfo["Application Identifier"])}</h2>
        {Object.entries(patentInfo).map(([key, value]) => {
          if (key === "patentJobId" || key === "pubJobId") {
            return null
          }
          if (key == "Patent Number" || key == "Early Publication Number") {
            var resourceType = ""
            var theJobId = ""
            let termMapData = {}
            let isTermMapVisible = false
            if (key == "Patent Number") {
              resourceType = "patent"
              theJobId = patentInfo.patentJobId
              termMapData = patTermMapData
              isTermMapVisible = showPatTermMapButton
            } else if (key == "Early Publication Number") {
              resourceType = "publication"
              theJobId = patentInfo.pubJobId
              termMapData = pubTermMapData
              isTermMapVisible = showPubTermMapButton
            }
            return (
              <div key={key}>
                {key}: <strong>{renderValue(value) || 'N/A'}</strong>
                {(!((theJobId !== "" && resourceType == "patent") || (theJobId !== "" && resourceType == "publication"))) &&
                    (<button onClick={() => deepDivePatentTerms(value, patentInfo["Application Identifier"], resourceType)}
                        className={`${styles.colorCycleStrong} text-white text-xs rounded relative ml-1`}>
                         <div className='px-1 py-1 drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)] rounded inset-0 hover:opacity-70 hover:bg-blue-700'>Analyze Spec.</div>
                    </button>)}
                {((theJobId !== "") && (<ResourceProcessingStatus
                    key={theJobId + "-info-component"}
                    resourceType={resourceType}
                    resourcePubNo={value}
                    resourceName={"- analyzing spec."}
                    jobId={theJobId}
                    completeCallback={resourceComplete}
                    hideName={false}
                  />
                ))}
                 <div>
                    {showPatTermMapButton && key == "Patent Number" && (
                      <><button
                        onClick={() => setIsPatTermMapVisible(!isPatTermMapVisible)}
                        className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600"
                      >
                        {isPatTermMapVisible ? 'Hide Term Map' : 'Show Term Map'}
                      </button>
                      {isPatTermMapVisible && (<TermMap termMap={termMapData} onClose={() => setIsPatTermMapVisible(false)} />)}</>
                    )}
                    {showPubTermMapButton && key == "Early Publication Number" && (
                      <><button
                        onClick={() => setIsPubTermMapVisible(!isPubTermMapVisible)}
                        className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600"
                      >
                        {isPubTermMapVisible ? 'Hide Term Map' : 'Show Term Map'}
                      </button>
                      {isPubTermMapVisible && (<TermMap termMap={termMapData} onClose={() => setIsPubTermMapVisible(false)}/>)}</>
                    )}
                 </div>
              </div>
            )
          }
          if (key == "Title") {
            return (
              <p key={key}>
                <strong>{renderValue(value) || 'N/A'}</strong>
              </p>
            )
          }
          if (key != "Application Identifier") {
            return (
              <p key={key}>
                <i>{lookupKey(key)}:</i> <strong>{renderValue(value) || 'N/A'}</strong>
              </p>
            )
          }
        })}
      </div>
      {showCustomAnalysis && <CustomAnalysis onClose={() => setShowCustomAnalysis(false)} patentNumber={patentInfo["Patent Number"]} pubNumber={patentInfo["Early Publication Number"]} />}

      <button onClick={() => setShowCustomAnalysis(true)} className="bg-green-500 text-white p-2 rounded">
        Custom Analysis
      </button>
    </div>
  )
}

export default PatentInfoComponent
