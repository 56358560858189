import React, { useState } from 'react'
import { userLogin } from './api'
import SignupForm from './SignupForm'

function LoginForm({ onLogin, goToPaymentSite, onSignUp }) {
  const [loginFailed, setLoginFailed] = useState(false)
  const [loginError, setLoginError] = useState(false)
  const [loginSuspended, setLoginSuspended] = useState(false)
  const [loginData, setLoginData] = useState({
    username: '',
    password: ''
  })

  const handleLoginChange = (e) => {
    const { name, value } = e.target
    setLoginData((prevState) => ({ ...prevState, [name]: value }))
  }

  const handleLoginSubmit = (e) => {
    e.preventDefault()
    // Perform login request using Axios
    userLogin(loginData).then((results) => {
        if (results["refresh_token"] === "none") {
           setLoginSuspended(true)
           return
        }
        const loggedInUser = {
          username: loginData.username,
          email: loginData.email,
          token: results,
        }
        console.log(results)
        onLogin(results)
    })
    .catch(error => {
        if (error.response && error.response.status === 403) {
          setLoginFailed(true)
          setLoginError(false)
        } else {
          setLoginError(true)
          setLoginFailed(false)
        }
    })
  }

  return (
    <div className='flex items-center justify-center w-64'>
      <div className='p-4 m-2 border rounded shadow bg-blue-100'>
        <h2 className='text-2xl text-center font-bold mb-2'>Teacher Login</h2>
        <form onSubmit={handleLoginSubmit} className='flex flex-col space-y-2'>
          <input
            type='text'
            name='username'
            placeholder='Username'
            value={loginData.username}
            onChange={handleLoginChange}
            className='border rounded p-2'
          />
          <input
            type='password'
            name='password'
            placeholder='Password'
            value={loginData.password}
            onChange={handleLoginChange}
            className='border rounded p-2'
          />
          {loginFailed && (
            <div className="text-red-500 text-sm bg-red-100 p-2 rounded mb-2">
              Username or password incorrect!
            </div>
          )}
          {loginError && (
            <div className="text-red-500 text-sm bg-red-100 p-2 rounded mb-2">
              Error. If errors persist, contact info@forgen.ai
            </div>
          )}
          {loginSuspended && (
            <div>
              <div className="text-red-500 text-sm bg-red-100 p-2 rounded mb-2">
                Disabled due to non-payment. If you believe this is a mistake, contact info@forgen.ai
              </div>
              <div>
                Please complete payment to get started.
                  <div className="flex items-center justify-center">
                    <button
                      onClick={() => {goToPaymentSite(loginData.username)}}
                      className="bg-blue-500 text-white p-2 rounded"
                    >
                      Pay with Stripe
                    </button>
                  </div>
              </div>
            </div>
          )}
          <button type='submit' className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'>
            Teacher Login
          </button>
        </form>
        <button type='submit' onClick={() => {onSignUp()}} className='w-full mt-2 bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded'>
          Teacher Sign Up
        </button>
      </div>
    </div>
  )
}

export default LoginForm
