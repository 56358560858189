import React, { useEffect, useState } from 'react'
import { getAssessmentInfo } from './api'
import RedX from './RedX'

function AssessmentItem({ assessmentId, deleteAssessmentByAssessmentId }) {
    const [assessmentText, setAssessmentText] = useState("")
    const [standardCode, setStandardCode] = useState("")
    const [deleteAssessmentVisible, setDeleteAssessmentVisible] = useState(false)
    const [isTextFull, setIsTextFull] = useState(false)

    const toggleText = () => {
      setIsTextFull(!isTextFull)
    }

    useEffect(() => {
        getAssessmentInfo(assessmentId).then((assessmentInfoResponse) => {
                if (assessmentInfoResponse && assessmentInfoResponse["assessment_text"]) {
                  setAssessmentText(assessmentInfoResponse["assessment_text"])
                }
                if (assessmentInfoResponse && assessmentInfoResponse["standard_code"]) {
                  setStandardCode(assessmentInfoResponse["standard_code"])
                }
            }).catch(() => {
            })

    }, [assessmentId])

    if (!assessmentText && !standardCode) {
        return <div>Loading...</div>
    }

    const showDeleteAssessment = () => {
      setDeleteAssessmentVisible(true)
    }

    const hideDeleteAssessment = () => {
      setDeleteAssessmentVisible(false)
    }

    async function deleteAssessment(){
        deleteAssessmentByAssessmentId(assessmentId)
        setDeleteAssessmentVisible(false)
    }

    const truncateText = (text, length) => {
      if (text.length > length) {
        return text.substring(0, length) + '...'
      }
      return text
    }

    const getAssessmentText = (the_text) => {
      if (the_text == ""){
        return "for each student, a custom scenario is generated"
      }
      return the_text
    }

    const textColorClass = assessmentText === "" ? "text-gray-400 italic" : "text-black"

    return (
    <div className="flex justify-between items-center bg-white shadow-md px-4 py-2 rounded-lg">
        <div className="text-sm text-gray-700 font-medium">{standardCode}</div>
        <div className={`text-sm ${textColorClass}`}>
          {isTextFull ? getAssessmentText(assessmentText) : getAssessmentText(truncateText(assessmentText, 50))} {/* Adjust 100 to your preferred length */}
        </div>
        {assessmentText != "" && (
          <button onClick={toggleText} className="text-blue-500 bg-white hover:bg-blue-500 hover:text-white rounded-full p-1">
            {isTextFull ? '(-)' : '(+)'}
          </button>
        )}
        <button
          onClick={showDeleteAssessment}
          className="bg-white p-2 rounded-full hover:shadow-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          aria-label="Delete Assessment"
        >
          <RedX />
        </button>
        {deleteAssessmentVisible && (
            <div className="fixed z-10 inset-0 overflow-y-auto">
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <div className="fixed inset-0 transition-opacity">
                        <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                    </div>
                    <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                        <div>
                            <div className="text-lg leading-6 font-medium text-gray-900 mb-4">
                                Delete Standard
                            </div>
                            <div className="w-full items-stretch">
                              <div className="inline" >Are you sure you want to delete {assessmentText}?</div>
                            </div>
                        </div>
                        <div className="mt-5 sm:mt-6">
                            <span className="flex w-full rounded-md shadow-sm">
                                <button onClick={deleteAssessment} type="button" className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-pink-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                                    Confirm Delete {assessmentText}
                                </button>
                                <button onClick={hideDeleteAssessment} type="button" className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-blue-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                                    Cancel
                                </button>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        )}
    </div>
    )
}

export default AssessmentItem
