import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Spinner from './Spinner'
import classNames from 'classnames'

import { getJobStatus } from './api'

function ResourceProcessingStatus({ resourceType, resourcePubNo, jobId, resourceName, completeCallback, hideName }) {
  const JOB_REFRESH_INTERVAL = 15000
  const JOB_DESC_REFRESH_INTERVAL = 15000
  const [status, setStatus] = useState('processing')
  const [isLoading, setIsLoading] = useState(true)
  const [isVisible, setIsVisible] = useState(true)
  const statusClassName = classNames('text-base', {
    'text-green-500 font-bold': status.startsWith('complete'),
    'text-blue-500': status === 'processing',
    'text-red-500': status === 'error',
  })

  const handleClose = () => {
    setIsVisible(false)
  }

  useEffect(() => {
    if (status !== 'processing') {
      setIsLoading(false)
    }
  }, [status])
  useEffect(() => {
    if (jobId && jobId !== null && jobId.startsWith('complete')) {
      setStatus('Complete (already loaded)')
      setIsLoading(false)
      return
    }
    let intervalId
    if (!status.toLowerCase().startsWith('complete') && !status.toLowerCase().startsWith('error') && !status.toLowerCase().startsWith('unknown')) {
      intervalId = setInterval(async () => {
        try {
          const res = await getJobStatus(jobId)
          if (res.status && res.status === 'error') {
            setStatus(res.status.toLowerCase())
            clearInterval(intervalId)
          } else if (res.status && res.status.startsWith('complete-')) {
            setStatus('complete')
            clearInterval(intervalId)
            if (resourceName.includes("-info-")) {
              completeCallback(res.status.split("-")[1])
            }
          } else {
            setStatus(res.status.toLowerCase())
            if (res.status && res.status === 'complete') {
              clearInterval(intervalId)
              if (resourceName.includes("-info-")) {
                completeCallback(res.status.split("-")[1])
              } else {
//              if (resourceType === "patent" || resourceType === "publication") {
                completeCallback(res.status.split("-")[1], resourceType, resourcePubNo)
              }
            }
          }
        } catch (error) {
          console.error(error)
        }
      }, (resourceName && resourceName.includes("-desc-")) ? JOB_DESC_REFRESH_INTERVAL : JOB_REFRESH_INTERVAL)
  }
    return () => clearInterval(intervalId)
  }, [])


  if (!isVisible || jobId === "") {
    return null;
  }
  return (
    <div>
      {!hideName && resourceName + ":"}{(isLoading || status.startsWith('processing')) && <Spinner />}
      <span className={statusClassName}>
        {status.startsWith('complete') && (
          <img src='green-checkmark.png' alt='' className='inline-flex h-4 pb-1 pl-1 pr-1' />
        )}
        {status.startsWith('error') && (
          <img src='red-x.png' alt='' className='inline-flex h-4 pb-1 pl-1 pr-1' />
        )}
        {status}
      </span>
      <button onClick={handleClose} className="ml-2 hover:bg-red-100 p-1 rounded-full transition duration-150 ease-in-out">
        <svg
          className="w-4 h-4"
          fill="none"
          stroke="red"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18L18 6M6 6l12 12"
          ></path>
        </svg>
      </button>
    </div>
  )
}

ResourceProcessingStatus.propTypes = {
  jobId: PropTypes.string.isRequired,
  resourceName: PropTypes.string.isRequired,
  completeCallback: PropTypes.func.isRequired,
  hideName: PropTypes.bool.isRequired,
}

export default ResourceProcessingStatus
