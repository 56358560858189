import React from 'react'

function RedX() {
  return (
    <svg
        className="w-4 h-4"
        fill="none"
        stroke="red"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M6 18L18 6M6 6l12 12"
        ></path>
      </svg>
  )
}

export default RedX
