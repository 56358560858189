import React from 'react'

const OpenAIIcon = () => {
    return (
        <div className="md:fixed Md:mt-4 relative md:right-10 md:bottom-20 z-50">
            <img src="/powered-by-openai-badge-filled-on-light.svg" alt="Powered by OpenAI" className="w-48" />
        </div>
    )
}

export default OpenAIIcon
