import React, { useState } from 'react'
import { uploadFiles } from './api'
import FileProcessingStatus from './FileProcessingStatus'
import PropTypes from 'prop-types'

const FileInput = ({ completeCallback, addResources }) => {
  const [files, setFiles] = useState([])
  const [jobIds, setJobIds] = useState([])
  const [resourceNames, setResourceNames] = useState([])
  const [resourceIds, setResourceIds] = useState([])

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files)
    setFiles(selectedFiles)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (files.length === 0) {
      alert('Please select at least one file before uploading')
      return
    }

    try {
      const formData = new FormData()
      files.forEach((file) => {
        formData.append('files', file)
      })
      // formData.append('cluster_id', selectedCluster.cluster_id)
      uploadFiles(formData)
        .then((res) => {
          setJobIds(res.job_ids)
          setResourceNames(res.resource_names)
          setResourceIds(res.resource_ids)
          addResources(res.job_ids, res.resource_names)
        })
        .catch((error) => {
          console.error(error)
        })
    } catch (error) {
      console.error(error)
    }
  }

  const inputLoaded = (resource_name, job_id) => {
      console.log("setting new_resource_ids: " + resource_name)
      localStorage.setItem('new_resource_ids', resource_name)
      completeCallback(resource_name)
  }

  return (
    <div className='mb-10 mr-20 p-6 bg-white rounded shadow-md'>
      <form onSubmit={handleSubmit} className='p-2'>
        <input
          type='file'
          onChange={handleFileChange}
          className='w-full p-2 mb-4 border rounded'
          multiple
        />
        {files.length > 0 && (
          <ul>
            {files.map((file, index) => (
              <li key={index}>{file.name}</li>
            ))}
          </ul>
        )}
        <div className='flex justify-between'>
          <button
            type='submit'
            className='px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-700'
          >
            Upload 
          </button>
        </div>
      </form>
      {jobIds.length > 0 && (
        <div className='text-right'>
          {jobIds.map((jobId, index) => (
            <FileProcessingStatus key={index} jobId={jobId} resourceName={resourceNames[index]} completeCallback={() => {inputLoaded(resourceNames[index], jobId)}} />
          ))}
        </div>
      )}
    </div>
  )
}

/* FileInput.propTypes = {
  selectedCluster: PropTypes.shape({
    cluster_id: PropTypes.string.isRequired,
    cluster_name: PropTypes.string.isRequired,
  }).isRequired,
}*/

export default FileInput
