import React, { useEffect, useState } from 'react'
import { getClassInfo, addNewStudentToClass, deleteClassByClassId, deleteStudentByClassAndStudentId, getScores } from './api'
import StudentItem from './StudentItem'
import GradeTable from './GradeTable'

function ClassItem({ classId, deleteClassCallback, examsForClass }) {
    const [className, setClassName] = useState(null)
    const [classGrade, setClassGrade] = useState(null)
    const [roster, setRoster] = useState([])
    const [downloadObject, setDownloadObject] = useState([])
    const [addStudentVisible, setAddStudentVisible] = useState(false)
    const [bulkAddStudentVisible, setBulkAddStudentVisible] = useState(false)
    const [deleteClassVisible, setDeleteClassVisible] = useState(false)
    const [newStudentName, setNewStudentName] = useState("")
    const [bulkNewStudentName, setBulkNewStudentName] = useState("")
    const [newStudentEmail, setNewStudentEmail] = useState("")
    const [examId, setExamId] = useState("")
    const [shouldRender, setShouldRender] = useState(true)
    const [gradeData, setGradeData] = useState({})
    const [gradesVisible, setGradesVisible] = useState(false)
    const [examsNamesVisible, setExamsNamesVisible] = useState(false)

    const [examScoreSelectVisible, setExamScoreSelectVisible] = useState(false)
    const [rosterVisible, setRosterVisible] = useState(false)
    const showRoster = () => {
      setRosterVisible(true)
    }

    const showExamNames = () =>{
      setExamsNamesVisible(true)
    }

    const hideExamNames= () =>{
      setExamsNamesVisible(false)
    }
    const hideRoster = () => {
      setRosterVisible(false)
    }
    const getClassData = (classId) => {
      getClassInfo(classId).then((classInfoResponse) => {
              if (classInfoResponse && classInfoResponse["class_name"]) {
                setClassName(classInfoResponse["class_name"])
              }
              if (classInfoResponse && classInfoResponse["class_grade"]) {
                setClassGrade(classInfoResponse["class_grade"])
              }
              if (classInfoResponse && classInfoResponse["roster"]) {
//                const the_roster = classInfoResponse["roster"]
                let the_roster = classInfoResponse["roster"]
                the_roster = the_roster.sort((a, b) => a.student_name.localeCompare(b.student_name))
                setRoster(the_roster)
              }
          }).catch(() => {
          })
   }


  const XLSX = require('xlsx')
  function createExcelFile() {
    let rows = []

    Object.keys(downloadObject).forEach(studentId => {
        let studentRow = {
            student_id: studentId,
            student_name: downloadObject[studentId][Object.keys(downloadObject[studentId])[0]].student_name // Assuming the student name is the same across assessments
        }
        
        let totalOverallGrade = 0
        let assessmentsCount = 0

        Object.values(downloadObject[studentId]).forEach(assessment => {
            assessment.exam_grades.forEach((grade, index) => {
                studentRow[`grade_${assessmentsCount}_${index + 1}`] = grade
            })
            studentRow[`overall_${assessmentsCount + 1}`] = assessment.overall_grade
            totalOverallGrade += assessment.overall_grade
            assessmentsCount++
        })

        studentRow.total_average_grade = (totalOverallGrade / assessmentsCount).toFixed(2) // Average of overall grades
        rows.push(studentRow)
    })

    const ws = XLSX.utils.json_to_sheet(rows)
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, "Scores")

    XLSX.writeFile(wb, "StudentScores.xlsx")
}


   const downloadScores = () => {
      createExcelFile()
    }

   const hideGrades = () => {
    setGradesVisible(false)
   }

  const getStudentNameById = (studentId) => {
    const student = roster.find(s => s.student_id === studentId);
    return student ? student.student_name : null;
  }

  const loadScores = async(examId) => {
      setExamScoreSelectVisible(false)
      getScores(examId).then((scoreResponse) => {
          let data = scoreResponse
          let students = {} // Map of student_id to their grades for each assessment
          let student_grades = {}
            Object.keys(data).forEach(assessmentId => {
              student_grades[assessmentId] = {}
            data[assessmentId]["scores"].forEach(record => {
              const { student_id, total_grade, exam_grades, messages } = record
              const standard_code = data[assessmentId]["standard_code"]
              if (!students[student_id]) students[student_id] = {}
              const student_name = getStudentNameById(student_id)
//              let overall_grade = Math.round(total_grade / exam_grades.length)
              let overall_grade = total_grade
              let grade_string = ""
              for (let j = 0; j < exam_grades.length; j++){
                  grade_string += exam_grades[j]
                  if (j == 0) {
                    grade_string += "(DCI), "
                  }
                  if (j == 1) {
                    grade_string += "(SEP), "
                  }
                  if (j == 2) {
                    grade_string += "(CCC), "
                  }
                  if (j == 3) {
                    grade_string += "(Ext.)"
                  }
              }
              students[student_id][assessmentId] = { student_name, student_id, standard_code, overall_grade, exam_grades, grade_string, messages }
              student_grades[assessmentId][student_id] = { student_name, student_id, standard_code, overall_grade, exam_grades, grade_string, messages }
            })
          })
          setGradeData(student_grades)
          setDownloadObject(students)
          setExamId(examId)
          setGradesVisible(true)
//          for (let j= 0; j < scoreResponse.assessments.length; j++){
//            assessmentId =
//            for (let j= 0; j < scoreResponse.assessments.length; j++){
//              scores[assessmentId][studentId] =
//            }
//          }
          }).catch(() => {
          })
   }

   const viewLoadScores = async() =>{
      if (examsForClass && examsForClass.length > 0) {
        setExamScoreSelectVisible(true)
      }
   }

    useEffect(() => {
      getClassData(classId)
    }, [classId])

    if (!className) {
        return <div>Loading...</div>
    }

    const removeComponent = () => {
        setShouldRender(false)
    }

    if (!shouldRender) {
        return null
    }

    const showAddNewStudent = () => {
      setAddStudentVisible(true)
    }

    const cancelAddNewStudent = () => {
      setAddStudentVisible(false)
    }

    const showBulkAddNewStudent = () => {
      setBulkAddStudentVisible(true)
    }

    const cancelBulkAddNewStudent = () => {
      setBulkAddStudentVisible(false)
    }

    const showDeleteClass = () => {
      setDeleteClassVisible(true)
    }

    const hideDeleteClass = () => {
      setDeleteClassVisible(false)
    }

    async function addNewStudent(){
        const studentData = {
            student_name: newStudentName,
            student_email: newStudentEmail,
        }
        setNewStudentEmail("")
        setNewStudentName("")
        await addNewStudentToClass(classId, studentData)
        setAddStudentVisible(false)
        getClassData(classId)
    }

     async function bulkAddNewStudent(){
        const names = bulkNewStudentName.split(";")
        if (names && names.length < 1) {
          return
        }
        setBulkNewStudentName("")
        for (let i=0; i < names.length; i++){
          const studentData = {
              student_name: names[i],
              student_email: "",
          }
          await addNewStudentToClass(classId, studentData)
        }
        setBulkAddStudentVisible(false)
        getClassData(classId)
    }

    async function deleteClass(){
        await deleteClassByClassId(classId)
        setDeleteClassVisible(false)
        removeComponent()
    }

    async function deleteStudent(studentId){
        await deleteStudentByClassAndStudentId(classId, studentId)
        getClassData(classId)
    }


//            {examsForClass && (
//            <div>
//                {!examsNamesVisible && (
//                  <button onClick={showExamNames} type="button" className="inline-flex justify-center w-full rounded-md border border-transparent my-1 bg-green-300 text-base leading-6 font-medium text-white shadow-sm hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-green transition ease-in-out duration-150 sm:text-sm sm:leading-5">
//                      View Exam Names
//                  </button>
//                )}
//                {examsNamesVisible && examsForClass.map(id =>
//                  <button onClick={() => loadScores(id["exam_id"])} className="inline-flex justify-center w-full rounded-md border border-transparent my-1 bg-blue-300 text-base leading-6 font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">{id["exam_name"]}</button>
//                )}
//                {examsNamesVisible &&
//                  <button onClick={hideExamNames} type="button" className="inline-flex justify-center w-full rounded-md border border-transparent my-1 bg-red-300 text-base leading-6 font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5">
//                      Hide Exam Names
//                  </button>
//                }
//            </div>)}

    return (
        <div className="border rounded m-2 p-1 bg-green-100">
            {/* Render your class information here */}
            <div className="text-lg">{className}</div>
            <div>Grade: {classGrade}, Id: {classId}</div>
            {roster && !rosterVisible && (
              <button onClick={showRoster} type="button" className="inline-flex justify-center w-full rounded-md border border-transparent my-1 bg-green-300 text-base leading-6 font-medium text-white shadow-sm hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-green transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                  View Roster
              </button>
            )}
            {roster && rosterVisible && (
              <button onClick={hideRoster} type="button" className="inline-flex justify-center w-full rounded-md border border-transparent my-1 bg-green-300 text-base leading-6 font-medium text-white shadow-sm hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-green transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                  Hide Roster
              </button>
            )}
            {rosterVisible && roster && (
              <div className="fixed m-0 z-10 top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 overflow-y-auto">
                <div className="p-4 bg-white rounded-lg shadow-md">
                  <div className="overflow-y-auto max-h-[calc(100vh-150px)]">
                      {roster.map(id =>
                        <StudentItem key={id} studentId={id["student_id"]} studentName={id["student_name"]} deleteStudentByStudentId={deleteStudent} />
                      )}
                  </div>
                  <button onClick={showAddNewStudent} type="button" className="inline-flex justify-center w-full rounded-md border border-transparent my-1 bg-green-300 text-base leading-6 font-medium text-white shadow-sm hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-green transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                      Add Student to Roster
                  </button>
                  <button onClick={showBulkAddNewStudent} type="button" className="inline-flex justify-center w-full rounded-md border border-transparent my-1 bg-green-300 text-base leading-6 font-medium text-white shadow-sm hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-green transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                      Bulk Add Students to Roster
                  </button>
                    <button
                      onClick={() => setRosterVisible(false)}
                      className="inline-flex justify-center w-full rounded-md border border-transparent my-1 bg-red-300 text-base leading-6 font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                    >
                      Go Back
                    </button>
                </div>
              </div>
              )
            }
            {examScoreSelectVisible && (
                <div className="fixed m-0 z-10 top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 overflow-y-auto">
                <div className="p-4 bg-white rounded-lg shadow-md">
                  <p className="mb-4">Select Exam to View Scores</p>
                  {examsForClass && examsForClass.map(id =>
                  <button onClick={() => loadScores(id["exam_id"])} type="button" className="inline-flex justify-center w-full rounded-md border border-transparent my-1 bg-green-300 text-base leading-6 font-medium text-white shadow-sm hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-green transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                      {id["exam_name"]}
                  </button>
                  )}
                  <button onClick={() => setExamScoreSelectVisible(false)} type="button" className="inline-flex justify-center w-full rounded-md border border-transparent my-1 bg-red-300 text-base leading-6 font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                    > Go Back
                  </button>
                </div>
              </div>
            )}
            {gradesVisible && (
              <div className="fixed m-0 z-10 top-0 left-0 w-full h-full flex justify-center bg-black bg-opacity-50 overflow-y-auto">
                <div className="p-4 bg-white rounded-lg shadow-md min-h-[250px]">
                  <p className="mb-4">Scores for {className}</p>
                  <div className="flex space-x-4 overflow-y-auto">
                    <GradeTable data={gradeData} student_grades={gradeData} roster={roster} examId={examId} />
                  </div>
                  {downloadObject && (<button onClick={downloadScores} type="button" className="inline-flex justify-center w-full rounded-md border border-transparent my-1 bg-blue-300 text-base leading-6 font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                      Download Scores (xlsx)
                    </button>
                  )}
                  <button onClick={hideGrades} type="button" className="inline-flex justify-center w-full rounded-md border border-transparent my-1 bg-green-300 text-base leading-6 font-medium text-white shadow-sm hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-green transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                      Hide Scores
                  </button>
                </div>
              </div>
            )}
            <button onClick={viewLoadScores} type="button" className="inline-flex justify-center w-full rounded-md border border-transparent my-1 bg-green-300 text-base leading-6 font-medium text-white shadow-sm hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-green transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                View Scores
            </button>
            <button onClick={showDeleteClass} type="button" className="inline-flex justify-center w-full rounded-md border border-transparent my-1 bg-red-300 text-base leading-6 font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                Delete Class
            </button>
            {addStudentVisible && (
                  <div className="fixed z-10 inset-0 overflow-y-auto">
                      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                          <div className="fixed inset-0 transition-opacity">
                              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                          </div>
                          <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                              <div>
                                  <div className="text-lg leading-6 font-medium text-gray-900 mb-4">
                                      Student Name
                                  </div>
                                  <div className="w-full items-stretch">
                                    <div className="inline" >Student Name: </div>
                                    <input
                                        type="text"
                                        value={newStudentName}
                                        onChange={(e) => setNewStudentName(e.target.value)}
                                        className="border rounded-md p-1 m-1 inline"
                                        placeholder="Enter student name"
                                    />
                                  </div>
                                  <div className="w-full items-stretch">
                                    <div className="inline" >Student Email: </div>
                                    <input
                                        type="text"
                                        value={newStudentEmail}
                                        onChange={(e) => setNewStudentEmail(e.target.value)}
                                        className="border rounded-md p-1 m-1 inline"
                                        placeholder="Enter student email"
                                    />
                                  </div>
                              </div>
                              <div className="mt-5 sm:mt-6">
                                  <span className="flex w-full rounded-md shadow-sm">
                                      <button onClick={addNewStudent} type="button" className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-blue-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                                          Add Student
                                      </button>
                                      <button onClick={cancelAddNewStudent} type="button" className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-pink-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                                          Cancel
                                      </button>
                                  </span>
                              </div>
                          </div>
                      </div>
                  </div>
              )}
               {bulkAddStudentVisible && (
                  <div className="fixed z-10 inset-0 overflow-y-auto">
                      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                          <div className="fixed inset-0 transition-opacity">
                              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                          </div>
                          <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                              <div>
                                  <div className="text-lg leading-6 font-medium text-gray-900 mb-4">
                                      Enter student names, each separated by a semicolon ';'
                                  </div>
                                  <div className="w-full items-stretch">
                                    <textarea
                                        value={bulkNewStudentName}
                                        onChange={(e) => setBulkNewStudentName(e.target.value)}
                                        className="border rounded-md p-1 m-1"
                                        placeholder="Enter student names, each separated by a semicolon ';'"
                                    />
                                  </div>
                              </div>
                              <div className="mt-5 sm:mt-6">
                                  <span className="flex w-full rounded-md shadow-sm">
                                      <button onClick={bulkAddNewStudent} type="button" className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-blue-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                                          Add Students
                                      </button>
                                      <button onClick={cancelBulkAddNewStudent} type="button" className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-pink-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                                          Cancel
                                      </button>
                                  </span>
                              </div>
                          </div>
                      </div>
                  </div>
              )}
            {deleteClassVisible && (
                  <div className="fixed z-10 inset-0 overflow-y-auto">
                      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                          <div className="fixed inset-0 transition-opacity">
                              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                          </div>
                          <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                              <div>
                                  <div className="text-lg leading-6 font-medium text-gray-900 mb-4">
                                      Delete Class
                                  </div>
                                  <div className="w-full items-stretch">
                                    <div className="inline" >Are you sure you want to delete {className}?</div>
                                  </div>
                              </div>
                              <div className="mt-5 sm:mt-6">
                                  <span className="flex w-full rounded-md shadow-sm">
                                      <button onClick={deleteClass} type="button" className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-pink-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                                          Confirm Delete {className}
                                      </button>
                                      <button onClick={hideDeleteClass} type="button" className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-blue-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                                          Cancel
                                      </button>
                                  </span>
                              </div>
                          </div>
                      </div>
                  </div>
              )}
        </div>
    )
}

export default ClassItem
