import React from 'react'

const ForGenAI = () => {
     return (
     <a href="https://forgen.ai/" target="_blank">
      <div className="md:fixed mt-4 relative border-radius rounded-md text-center md:right-10 bottom-2 border border-black w-48 z-50 items-center justify-center bg-white px-4 py-1 pb-2 rounded border">
        <span className="text-md tracking-tight font-bold font-sans">Turbo-Charged by</span>
        <img src='forgen_ai.png' className="ml-5" alt="ForGen AI" width="120" height="72" /> {/* Adjust width and height as needed */}
      </div>
      </a>
  )
}

export default ForGenAI
