import React, { useState, useRef, useEffect } from 'react'
import { sendMessage, continueMessage, getMessage, getPatentInfo, addNewChat, saveChat, getExamInfo, getExamInfoForStudent, startAssessment, gradeExam, generateFeedback } from './api'
import ResourceProcessingStatus from './ResourceProcessingStatus'
import TypingDots from './TypingDots'
import styles from './ColorCycle.module.css'
import PatentInfoComponent from './PatentInfoComponent'
import ChatList from './ChatList'
import FileInput from './FileInput'
import ContextSummary from './ContextSummary'
import jsPDF from 'jspdf'
import * as XLSX from 'xlsx'

function ChatRoom({ userInfo, isSaved, setIsSaved, examId, examName, studentId, studentName, assessments, onRemoveAssessment }) {
    const [messages, setMessages] = useState([])
    const [resources, setResources] = useState([])
    const [input, setInput] = useState('')
    const [loading, setLoading] = useState(false)
    const messagesEndRef = useRef(null)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isUploadModalOpen, setIsUploadModalOpen] = useState(false)
    const [showAssessmentOptions, setShowAssessmentOptions] = useState(true)
    const [showWarning, setShowWarning] = useState(false)
    const [feedbackVisible, setFeedbackVisible] = useState(false)
    const [chatName, setChatName] = useState('')
    const [chatId, setChatId] = useState('')
    const [modalErrorMessage, setModalErrorMessage] = useState(null)
    const [data, setData] = useState({
        patent_numbers: [],
        patent_application_numbers: [],
        patent_publication_numbers: []
    })
    const chatListRef = useRef(null)
    const [patents, setPatents] = useState([])
    const [curStandardMessages, setCurStandardMessages] = useState([])
    const messagesRef = useRef(messages)
    const chatIdRef = useRef(chatId)
    const [isContextVisible, setIsContextVisible] = useState(false)
    const [selectedReference, setSelectedReference] = useState(null)
    const [selectedAssessment, setSelectedAssessment] = useState({})

    const handleReferenceClick = (itemType, item) => {
      setSelectedReference({ itemType, item })
    }

    const mapping = {
        "applId": "Application Identifier",
        "patentTitle": "Title",
        "appFilingDate": "Application Filing Date",
        "patentNumber": "Patent Number",
        "patentIssueDate": "Patent Issue Date",
        "appEarlyPubNumber": "Early Publication Number",
        "appEarlyPubDate": "Early Publication Date",
        "appStatus": "Application Status",
        "appExamName": "Patent Examiner Name",
        "inventors": "Inventors",
        "appGrpArtNumber": "Group Art Number",
        "appType": "Application Type",
        "lastUpdatedTimestamp": "Last Updated",
    }
    useEffect(() => {
        messagesRef.current = messages
    }, [messages])

    useEffect(() => {
        chatIdRef.current = chatId
    }, [chatId])

    useEffect(() => {
      const storedHistory = localStorage.getItem('history') || ""
      if (!storedHistory) {
        setMessages([{
            role: "assistant",
            content: "Hello " + studentName + ", and welcome to assessments.chat! You have logged in to an exam entitled '" + examName + "'. Let's begin. Which standard would you like to start with?",
        }])
      } else {
        setMessages(JSON.parse(storedHistory))
      }
    }, [])

    const handleCurrentChatChange = (chat) => {
      setMessages(chat.chat_history)
      localStorage.setItem('chat_context', JSON.stringify(chat.chat_context))
      localStorage.setItem('chat_name', chat.chat_name)
      localStorage.setItem('chat_id', chat.chat_id)
      window.dispatchEvent(new Event('storage-update'))
      setChatName(chat.chat_name)
      setChatId(chat.chat_id)
      setIsSaved(true)
      if (chat.patents) {
        let chatPatents = []
        chat.patents.forEach(function (item, index) {
            const orderedObj = {}
            Object.keys(mapping).forEach(key => {
//                if (item[mapping[key]] !== undefined) {
                    orderedObj[mapping[key]] = item[mapping[key]]
//                }
            })
            orderedObj['patentJobId'] = item['patentJobId']
            orderedObj['pubJobId'] = item['pubJobId']
            chatPatents.push(orderedObj)
        })
        localStorage.setItem('patents', JSON.stringify(chatPatents))
        setPatents(chatPatents)
      }
    }

    function filterAndMapKeys(obj) {
        const filteredObj = {}

        for (const key in obj) {
            if (mapping[key]) {
                filteredObj[mapping[key]] = obj[key]
            }
        }

        return filteredObj
    }

    const downloadExcel = () => {
      const worksheet = XLSX.utils.json_to_sheet(messages)
      const workbook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, worksheet, "Transcript")
      XLSX.writeFile(workbook, studentName + " (" + studentId + ") - " + examName + " (" + examId + ").xlsx")
    }

    const removePatent = (applicationNumber) => {
      setPatents(prevPatents => prevPatents.filter(patent => patent["Application Identifier"] !== applicationNumber))
    }

    function updateResources(newJobInfo) {
        const newResources = processJobInfo(newJobInfo)
        setResources(prevResources => [...prevResources, ...newResources])
    }

//    useEffect(() => {
//      if(localStorage.getItem('patents') !== null && localStorage.getItem('patents') !== ""){
//        setPatents(JSON.parse(localStorage.getItem('patents')))
//      }
//      const storedHistory = localStorage.getItem('history', "")
//      if (!(storedHistory && storedHistory !== "")){
//        setMessages([{
//            role: "assistant",
//            content: "Hello, and welcome to patents.chat! Ask me about patents, including a specific US case by typing in the patent number, application number, or application publication number.",
//        }])
//      } else if (storedHistory && storedHistory !== "") {
//        setMessages(JSON.parse(storedHistory))
//        const storedIsSaved = JSON.parse(localStorage.getItem('storedIsSaved'))
//        if (typeof storedIsSaved === 'boolean') {
//          setIsSaved(storedIsSaved)
//        }
//      }
//    },[])

    const handleSend = () => {
        if (loading){
          return
        }
        if (input.trim() !== '') {
            setInput('')
            const newMessage = { content: input, role: 'user' }
            const updatedMessages = [...messages, newMessage]
            let updatedCurStandardMessages
            if (curStandardMessages.length == 0) {
                updatedCurStandardMessages = [messages[2], ...curStandardMessages, newMessage]
            } else {
              updatedCurStandardMessages = [...curStandardMessages, newMessage]
            }
            setMessages(updatedMessages)
            setCurStandardMessages(updatedCurStandardMessages)
            setIsSaved(false)
            setTimeout(() => {
                setLoading(true)
                messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
            }, 50)
            const sendMessageCallback = (sendMessageResponse) => {
                const message_id = sendMessageResponse["message_id"]
                const interval = setInterval(() => {
                    getMessage(message_id).then((getMessageResponse) => {
                      if (getMessageResponse['message'] && (getMessageResponse['message'].toUpperCase().trim() == "DONE" || getMessageResponse['message'].toUpperCase().includes("DONE"))){
                          onRemoveAssessment(selectedAssessment)
                          clearInterval(interval)
                          gradeExam(examId, studentId, selectedAssessment.assessment_id, updatedCurStandardMessages).then((gradeExamResponse) => {
                              if (gradeExamResponse && gradeExamResponse["status"] && gradeExamResponse["status"] == "incomplete"){
                                  continueMessage(input, updatedMessages, data, studentId).then(
                                    sendMessageCallback
//                                  (sendMessageResponse) => {
//                                    const message_id = sendMessageResponse["message_id"]
//                                    const interval2 = setInterval(() => {
//                                        getMessage(message_id).then((getMessageResponse2) => {
  //                                        if (getMessageResponse2['message']) {
  //                                          setLoading(false)
  //                                          setMessages(prevMessages => [...prevMessages, { content: getMessageResponse2['message'], role: 'assistant' }])
  //                                          setCurStandardMessages(prevMessages => [...prevMessages, { content: getMessageResponse2['message'], role: 'assistant' }])
  //                                          setIsSaved(false)
  //                                          clearInterval(interval2)
  //                                        }
//                                      })
//                                    }, 3500)
//                                  }
                                  )
                                return
                              }
                              setCurStandardMessages([])
                              clearInterval(interval)
                              setLoading(false)
                              if (assessments.length < 1 || (assessments.length == 1 && assessments[0] == selectedAssessment)){
                                setMessages(prevMessages => [...prevMessages, { content: "You have completed all standards. Your exam has ended.", role: 'assistant' }])
                                setFeedbackVisible(true)
                              } else {
                                setMessages(prevMessages => [...prevMessages, { content: "Please select the next standard.", role: 'assistant' }])
                                setShowAssessmentOptions(true)
                              }
                          })
                      } else if (getMessageResponse['message'] && getMessageResponse['message'] !== undefined && getMessageResponse['message'] !== null && getMessageResponse['message'] !== "null") {
                          setLoading(false)
                          setMessages(prevMessages => [...prevMessages, { content: getMessageResponse['message'], role: 'assistant' }])
                          setCurStandardMessages(prevMessages => [...prevMessages, { content: getMessageResponse['message'], role: 'assistant' }])
                          setIsSaved(false)
                          clearInterval(interval)
                        }
                    }).catch(() => {
                        setLoading(false)
                        clearInterval(interval)
                    })
                }, 3500)
            }
            sendMessage(input, updatedMessages, data, studentId).then(sendMessageCallback).catch(() => {
                setLoading(false)
            })
        }
    }
    const handleEnterKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault()
            handleSend()
        }
    }

    async function handleKeyDown(e) {
         if ((e.ctrlKey || e.metaKey) && e.key === "s") {
          e.preventDefault()
          await saveCurrentChat()
        }
    }

   const downloadReportPDF = (report) => {
      const doc = new jsPDF()
      let yPosition = 10
      const lineHeight = 7
      const maxWidth = 180
      const pageHeight = doc.internal.pageSize.height

      let lines = doc.splitTextToSize(report, maxWidth)

      lines.forEach(line => {
        if (yPosition > pageHeight - 10) {
          doc.addPage()
          yPosition = 10
        }
        doc.text(line, 10, yPosition)
        yPosition += lineHeight
      })

      let filename = "Feedback for " + studentName + " - " + examId + ".pdf"
      doc.save(filename)
    }


    useEffect(() => {
      if (messagesEndRef.current) {
        messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
      }
    }, [messages, loading])

    function addChatMessage(newChatMessage){
       if (messages.length > 0 && newChatMessage === messages[messages.length - 1].content){
          return
       }
       const newMessages = [...messages, { content: newChatMessage, role: 'assistant' }]
       setMessages(newMessages)
       localStorage.setItem('history', JSON.stringify(newMessages))
       setIsSaved(false)
    }

    function processJobInfo(jobInfo) {
        const resourceTypes = [
          { type: "patent_application", key: "patent_application_resource_ids" },
          { type: "patent", key: "patent_resource_ids" },
          { type: "patent_publication", key: "patent_publication_resource_ids" }
        ]

        let resources = []

        resourceTypes.forEach(resourceType => {
          const jobKey = `${resourceType.type}_job_ids`
          jobInfo[jobKey].forEach((job, index) => {
            resources.push({
              type: resourceType.type,
              resourceName: jobInfo[resourceType.key][index]?.resource_name,
              jobId: job.job_id
            })
          })
        })

        return resources
    }

    useEffect(() => {
      const curInput = localStorage.getItem('curInput')
      if (curInput && curInput !== "") {
        setInput(curInput)
      }
      const prevChatName = localStorage.getItem('chat_name')
      if (prevChatName && prevChatName !== "") {
        setChatName(prevChatName)
      }
      const prevChatId = localStorage.getItem('chat_id')
      if (prevChatId && prevChatId !== "") {
        setChatId(prevChatId)
        setIsSaved(true)
      }

      window.addEventListener('keydown', handleKeyDown)
      return () => {
        window.removeEventListener('keydown', handleKeyDown)
      }
    }, [])

    useEffect(() => {
      localStorage.setItem('curInput', input)
    }, [input])

    useEffect(() => {
      if (patents && patents.length > 0)
       localStorage.setItem('patents', JSON.stringify(patents))
    }, [patents])

    function addOrUpdatePatent(newPatent) {
      setPatents(prevPatents => {
//          removePatent(newPatent["Application Identifier"])
        const filteredPatents = prevPatents.filter(patent => patent["Application Identifier"] !== newPatent["Application Identifier"])
        newPatent.patentJobId = ""
        newPatent.pubJobId = ""
        return [newPatent, ...filteredPatents]
      })
    }

    function showClearChatMessagesWarning(){
        if (messages.length > 1 && !isSaved){
           setShowWarning(true)
        }
        if (messages.length > 1 && isSaved) {
          clearChatMessages()
        }
    }

    function clearChatMessages(){
      localStorage.setItem('history', "")
      localStorage.setItem('patents', "")
      localStorage.setItem('chat_context', "")
      localStorage.setItem('chat_name', "")
      localStorage.setItem('chat_id', "")
      window.location.reload()
    }

    async function resourceComplete(applicationNumber) {
        const patent_obj = await getPatentInfo(applicationNumber)
        if (patent_obj["inventors"]) {
          const formatInventor = inventor => {
              return `${inventor.nameLineOne} ${inventor.nameLineTwo}, ${inventor.city} ${inventor.geoCode} ${inventor.country}`
          }
          patent_obj["inventors"] = patent_obj["inventors"].map(formatInventor).join(' ')
        }
        const filter_obj = filterAndMapKeys(patent_obj)
        for (const key in filter_obj) {
            if (key.toLowerCase().includes("date")) {
              filter_obj[key] = filter_obj[key].substring(0,10)
            }
        }
        const orderedObj = {}

        Object.keys(mapping).forEach(key => {
            if (filter_obj[mapping[key]] !== undefined) {
                orderedObj[mapping[key]] = filter_obj[mapping[key]]
            }
        })
        addOrUpdatePatent(orderedObj)
    }

    const handleAddNewChat = () => {
        setIsModalOpen(true)
    }

    const cancelAddNewChat = () => {
        setIsModalOpen(false)
    }

    const handleUploadFile = () => {
        setIsUploadModalOpen(true)
    }

    const cancelUploadFile = () => {
        setIsUploadModalOpen(false)
    }

    const handleToggleContext = () => {
        setIsContextVisible(!isContextVisible)
    }

    async function saveCurrentChat(){
        const chatData = {
            chat_id: chatIdRef.current,
            messages: messagesRef.current,
        }
        const saveChatResponse = await saveChat(chatData)
        if (saveChatResponse.message && saveChatResponse.message === "success") {
            console.log("Chat saved successfully!")
            chatListRef.current.triggerLoadChats()
            setIsSaved(true)
        }
    }

    async function resourceUploadComplete(resource_title){
        addChatMessage("I now know information about " + resource_title)
    }

    async function confirmAddNewChat() {
        if (chatName === "") {
            setModalErrorMessage("Please enter a name.")
            return
        }
        const chatData = {
            chat_name: chatName,
            messages: messages,
        }
        const addNewChatResponse = await addNewChat(chatData)
        if (addNewChatResponse.message && addNewChatResponse.chat_id) {
            console.log("Chat added successfully!")
            chatListRef.current.triggerLoadChats()
            setIsSaved(true)
            localStorage.setItem('chat_name', chatName)
            localStorage.setItem('chat_id', addNewChatResponse.chat_id)
        }
        setIsModalOpen(false)
    }

    const addNewResources = (jobIds, resourceNames) => {
        let newResources = []
        for (let i = 0; i < resourceNames.length; i++){
             let new_resource = {
              "type": "upload",
              "resourceName": resourceNames[i],
              "jobId": jobIds[i]
            }
            newResources.push(new_resource)
        }
        setResources(prevResources => [...prevResources, ...newResources])

    }


    const updateJobId = (applicationNumber, newPatentJobId, newPubJobId) => {
      setPatents((prevPatents) => {
        return prevPatents.map((patent) => {
          if (patent["Application Identifier"] === applicationNumber) {
            return {
              ...patent,
              patentJobId: newPatentJobId!==""?newPatentJobId:patent.patentJobId,
              pubJobId: newPubJobId!==""?newPubJobId:patent.pubJobId,
            }
          }
          return patent
        })
      })
    }

    const generateTheFeedback = () => {
        setFeedbackVisible(false)
        generateFeedback(examId, studentId, messages).then((genFeedbackResponse) => {
          if (genFeedbackResponse && genFeedbackResponse["report"]){
            downloadReportPDF(genFeedbackResponse["report"])
            return
          }
        })
    }

    const beginAssessment = async(assessment) => {
        setSelectedAssessment(assessment)
        setShowAssessmentOptions(false)
        setLoading(true)
        const patent_obj = await startAssessment(assessment.assessment_id)
        setMessages(prevMessages => [...prevMessages, { content: "You selected " + assessment.standard_code + ".", role: 'assistant' }])
        if (patent_obj["message_id"]) {
            const message_id = patent_obj["message_id"]
            const interval = setInterval(() => {
                getMessage(message_id).then((getMessageResponse) => {
                 if (getMessageResponse['message'] !== undefined && getMessageResponse['message'] !== null && getMessageResponse['message'] !== "null") {
                        setLoading(false)
                        setMessages(prevMessages => [...prevMessages, { content: getMessageResponse['message'], role: 'assistant' }])
                        setIsSaved(false)
                        clearInterval(interval)
                    }
                }).catch(() => {
                    setLoading(false)
                    clearInterval(interval)
                })
            }, 3500)
        }

    }

//              <div className='mr-4 p-4 w-72 bg-white rounded-lg shadow-md overflow-y-auto max-h-[calc(100vh-50px)] rounded'>
//                <div className='flex-col relative overflow-y-auto flex-col ml-2 rounded text-sm'>
//                  <ChatList setCurrentChat={handleCurrentChatChange} ref={chatListRef} />
//                </div>
//              </div>
    return (
        <div>
            <div className='flex'>
              <div className={`flex justify-center items-center h-screen flex-col min-w-[calc(40px)] max-w-[calc(600px)] max-h-[calc(100vh-50px)] overflow-hidden rounded-lg shadow-lg ${loading ? styles.colorCycleReverse : 'bg-white'}`}>
                <div className='w-full flex justify-center items-center bg-blue-100 rounded'>
                  <div className="m-2 md:w-[528px] rounded flex flex-col justify-center items-center">
                    <img src="scenarionaut_logo-thin.png" className="w-5/6"/>
                  </div>
                </div>
              <div className='flex-grow overflow-y-auto p-4 space-y-4'>
                  {messages.map((messageObj, idx) => {
                    const isSpecialMessage = messageObj.content.startsWith("You selected")
                    return (
                      <div key={idx} className={`flex ${isSpecialMessage ? 'justify-center' : messageObj.role === 'user' ? 'justify-end' : 'justify-start'}`}>
                        <div className={`p-2 border rounded-lg max-w-[80%] ${isSpecialMessage ? 'bg-green-200 text-black border-green-400' : messageObj.role === 'user' ? 'bg-blue-500 text-white border-blue-800' : 'bg-gray-200 text-black border-gray-400'}`}>
                          {messageObj.content}
                        </div>
                      </div>
                    )
                  })}
                  <div className="flex flex-wrap gap-4 justify-center">
                    {showAssessmentOptions && assessments.map((assessment) => (
                      <button
                        key={assessment.standard_code}
                        className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        type="button"
                        onClick={() => beginAssessment(assessment)}
                      >
                        {assessment.standard_code}
                      </button>
                    ))}
                  </div>
                   {loading && (
                       <div className='justify-start'>
                          <div className='p-2 rounded-lg flex max-w-[80%] w-fit pr-6 pl-6 h-10 items-center pl-3 bg-gray-200 text-black border-gray-400 border'>
                              <TypingDots />
                          </div>
                      </div>
                    )}
                  <div ref={messagesEndRef}></div>
                  {feedbackVisible && (
                    <button
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        type="button"
                        onClick={generateTheFeedback}
                      >
                      Generate Feedback
                      </button>
                  )}
              </div>
              <button onClick={downloadExcel} className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        type="button">
                Download Transcript
              </button>
              <div className='border-t p-4 bg-white rounded w-full'>
                  <div className='flex space-x-4'>
                      <textarea
                          className='flex-grow border rounded p-2'
                          value={input}
                          onChange={(e) => setInput(e.target.value)}
                          onKeyDown={handleEnterKeyDown}
                          placeholder='Type a message...'
                      />
                      <button onClick={handleSend} className={`${styles.colorCycleStrong} text-white rounded relative`}>
                        <div className='px-4 py-2 rounded inset-0 hover:opacity-70 hover:bg-blue-700'>Send</div>
                      </button>
                  </div>
              </div>
            </div>
            {isModalOpen && (
                  <div className="fixed z-10 inset-0 overflow-y-auto">
                      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                          <div className="fixed inset-0 transition-opacity">
                              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                          </div>
                          <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                              <div>
                                  <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">
                                      Name Your Chat
                                  </h3>
                                  <input
                                      type="text"
                                      value={chatName}
                                      onChange={(e) => setChatName(e.target.value)}
                                      className="w-full border rounded-md p-2"
                                      placeholder="Enter chat name"
                                  />
                              </div>
                              <div className="mt-5 sm:mt-6">
                                  {modalErrorMessage && (
                                      <div className="text-red-500 text-center text-sm bg-red-100 p-2 rounded mb-2">{modalErrorMessage}</div>
                                  )}
                                  <span className="flex w-full rounded-md shadow-sm">
                                      <button onClick={confirmAddNewChat} type="button" className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-blue-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                                          Confirm
                                      </button>
                                      <button onClick={cancelAddNewChat} type="button" className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-pink-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                                          Cancel
                                      </button>
                                  </span>
                              </div>
                          </div>
                      </div>
                  </div>
              )}
            {isUploadModalOpen && (
                  <div className="fixed z-10 inset-0 overflow-y-auto">
                      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                          <div className="fixed inset-0 transition-opacity">
                              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                          </div>
                          <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                              <div>
                                  <FileInput completeCallback={resourceUploadComplete} addResources={addNewResources} />
                              </div>
                              <div className="mt-5 sm:mt-6">
                                  {modalErrorMessage && (
                                      <div className="text-red-500 text-center text-sm bg-red-100 p-2 rounded mb-2">{modalErrorMessage}</div>
                                  )}
                                  <span className="flex w-full rounded-md shadow-sm">
                                      <button onClick={cancelUploadFile} type="button" className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-pink-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                                          Close Window
                                      </button>
                                  </span>
                              </div>
                          </div>
                      </div>
                  </div>
              )}
            <div className={`overflow-y-auto max-h-[calc(100vh-50px)] rounded`}>
              <div className='overflow-y-auto flex-col ml-2 rounded text-sm'>
                {patents.map((patent, index) => {
                return (
                <PatentInfoComponent
                    key={patent["Application Identifier"]}
                    patentInfo={patent}
                    patentJobId={patent.patentJobId}
                    pubJobId={patent.pubJobId}
                    onRemove={removePatent}
                    setChatMessage={addChatMessage}
                    updateJobId={updateJobId}
                  />
                )})}

              </div>
            </div>
          </div>
          <div>
          {showWarning && (
            <div className="fixed m-0 z-10 top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
              <div className="p-4 bg-white rounded-lg shadow-md">
                <p className="mb-4">Current chat will be lost. Make sure to save first.</p>
                <div className="flex space-x-4">
                  <button
                    onClick={clearChatMessages}
                    className="bg-red-500 text-white p-2 rounded"
                  >
                    Continue
                  </button>
                  <button
                    onClick={() => setShowWarning(false)}
                    className="bg-blue-500 text-white p-2 rounded"
                  >
                    Go Back to Save
                  </button>
                </div>
              </div>
            </div>
          )}
          </div>
        </div>
    )
}

export default ChatRoom
