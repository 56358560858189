import React, { useEffect, useState } from 'react'
import { getExamInfo, addNewAssessmentToExam, searchAssessmentCode, deleteAssessmentByIds, deleteExamByExamId, assignTestToClass, generateAnExample } from './api'
import AssessmentItem from './AssessmentItem'
import AssessmentDetails from './AssessmentDetails'
import Spinner from './Spinner'


function TestItem({ examId }) {
    const [examName, setExamName] = useState(null)
    const [assessments, setAssessments] = useState([])
    const [addAssessmentVisible, setAddAssessmentVisible] = useState(false)
    const [isExampleLoading, setIsExampleLoading] = useState(false)
    const [assignTestToClassVisible, setAssignTestToClassVisible] = useState(false)
    const [newAssessmentText, setNewAssessmentText] = useState("")
    const [newStandardCode, setNewStandardCode] = useState("")
    const [assignClassCode, setAssignClassCode] = useState("")
    const [assessmentData, setAssessmentData] = useState("")
    const [deleteTestVisible, setDeleteTestVisible] = useState(false)
    const [shouldRender, setShouldRender] = useState(true)
    const getExamData = (examId) => {
      getExamInfo(examId).then((examInfoResponse) => {
              if (examInfoResponse && examInfoResponse["exam_name"]) {
                setExamName(examInfoResponse["exam_name"])
              }
              if (examInfoResponse && examInfoResponse["assessments"]) {
                const the_assessments = examInfoResponse["assessments"].map(cls => cls.assessment_id)
                setAssessments(the_assessments)
              }
          }).catch(() => {
          })
   }

    useEffect(() => {
      getExamData(examId)
    }, [examId])

    if (!examName) {
        return <div>Loading...</div>
    }

    const showAddNewAssessment = () => {
      setAddAssessmentVisible(true)
    }

    const cancelAddNewAssessment = () => {
      setAddAssessmentVisible(false)
    }

    async function addNewAssessment(){
        const assessmentData = {
            assessment_text: newAssessmentText,
            standard_code: newStandardCode,
        }
        const addNewAssessmentToExamResponse = await addNewAssessmentToExam(examId, assessmentData)
        setAddAssessmentVisible(false)
        getExamData(examId)
    }

    async function deleteAssessmentById(assessmentId){
        const deleteAssessmentResponse = await deleteAssessmentByIds(examId, assessmentId)
        getExamData(examId)
    }


    const removeComponent = () => {
        setShouldRender(false)
    }

    if (!shouldRender) {
        return null
    }

    async function deleteTest(){
        const addNewStudentToClassResponse = await deleteExamByExamId(examId)
        setDeleteTestVisible(false)
        removeComponent()
    }

    async function checkStandardCode(){
        const checkAssessmentCodeResponse = await searchAssessmentCode(newStandardCode)
        if (checkAssessmentCodeResponse) {
          setAssessmentData(checkAssessmentCodeResponse)
          return
        }
        if (checkAssessmentCodeResponse && checkAssessmentCodeResponse["assessment_text"]){
          setNewAssessmentText(checkAssessmentCodeResponse["assessment_text"])
        }
        if (checkAssessmentCodeResponse && checkAssessmentCodeResponse["standard_code"]){
          setNewStandardCode(checkAssessmentCodeResponse["standard_code"])
        }
    }

    const showDeleteTest = () => {
      setDeleteTestVisible(true)
    }

    const hideDeleteTest = () => {
      setDeleteTestVisible(false)
    }

    const showAssignTestToClass = () => {
      setAssignTestToClassVisible(true)
    }

    const cancelAddTest = () => {
      setAssignTestToClassVisible(false)
    }

    async function addTest(){
        const assignTestResponse = await assignTestToClass(examId, assignClassCode)
        setAssignTestToClassVisible(false)
    }

    const generateExample = () => {
      setIsExampleLoading(true)
        generateAnExample(assessmentData).then((exampleResponse) => {
          setIsExampleLoading(false)
          if (exampleResponse && exampleResponse["example"]) {
            setNewAssessmentText(exampleResponse["example"])
          }
        }).catch(() => {
        setIsExampleLoading(false)
      })
    }

    return (
        <div className="border rounded m-2 p-1 bg-orange-100">
            {/* Render your class information here */}
            <div className="text-lg">{examName}</div>
            <div>Id: {examId}</div>
            {assessments && assessments.map(id =>
              <AssessmentItem key={id} assessmentId={id} deleteAssessmentByAssessmentId={deleteAssessmentById} />
            )}
            <button onClick={showAddNewAssessment} type="button" className="inline-flex justify-center w-full rounded-md border border-transparent my-1 bg-orange-300 text-base leading-6 font-medium text-white shadow-sm hover:bg-orange-500 focus:outline-none focus:border-orange-700 focus:shadow-outline-orange transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                Add Standard
            </button>
            <button onClick={showAssignTestToClass} type="button" className="inline-flex justify-center w-full rounded-md border border-transparent my-1 bg-blue-300 text-base leading-6 font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:border-orange-700 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                Assign to Class
            </button>
            <button onClick={showDeleteTest} type="button" className="inline-flex justify-center w-full rounded-md border border-transparent my-1 bg-red-300 text-base leading-6 font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                Delete
            </button>
            {addAssessmentVisible && (
                  <div className="fixed z-10 inset-0 overflow-y-auto">
                      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                          <div className="fixed inset-0 transition-opacity">
                              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                          </div>
                          <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                              <div>
                                  <div className="text-lg leading-6 font-medium text-gray-900 mb-4">
                                      Add New Standard to {examName}
                                  </div>
                                  <div className="w-full items-stretch">
                                    <div className="inline" >Standard Code: </div>
                                    <input
                                        type="text"
                                        value={newStandardCode}
                                        onChange={(e) => setNewStandardCode(e.target.value)}
                                        className="border rounded-md p-1 m-1 inline"
                                        placeholder="Enter assessment code"
                                    />
                                    <button onClick={checkStandardCode} className="inline-flex justify-center p-1 m-1 rounded-md border border-transparent my-1 bg-blue-300 text-base leading-6 font-medium text-white shadow-sm hover:bg-orange-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                                      Search
                                    </button>
                                  </div>
                                  <div className="w-full items-stretch">
                                    <div className="inline" >Custom Scenario / Instructions: </div>
                                    <textarea
                                        disabled={isExampleLoading}
                                        value={newAssessmentText}
                                        onChange={(e) => setNewAssessmentText(e.target.value)}
                                        className="border rounded-md p-1 m-1 w-full"
                                        placeholder="Enter custom scenario (optional). Default is a scenario is AI generated for each student."
                                    ></textarea>
                                      <button
                                        disabled={isExampleLoading}
                                        onClick={generateExample} className="inline-flex justify-center p-1 m-1 rounded-md border border-transparent my-1 bg-blue-300 text-base leading-6 font-medium text-white shadow-sm hover:bg-orange-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                                        Generate Example
                                      </button>
                                      {isExampleLoading && <Spinner />}
                                  </div>
                                  {assessmentData && (<AssessmentDetails standardSearchResultInfo={assessmentData} />)}
                              </div>
                              <div className="mt-5 sm:mt-6">
                                  <span className="flex w-full rounded-md shadow-sm">
                                      <button onClick={addNewAssessment} type="button" className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-blue-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                                          Add Standard
                                      </button>
                                      <button onClick={cancelAddNewAssessment} type="button" className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-pink-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                                          Cancel
                                      </button>
                                  </span>
                              </div>
                          </div>
                      </div>
                  </div>
              )}
              {assignTestToClassVisible && (
                  <div className="fixed z-10 inset-0 overflow-y-auto">
                      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                          <div className="fixed inset-0 transition-opacity">
                              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                          </div>
                          <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                              <div>
                                  <div className="text-lg leading-6 font-medium text-gray-900 mb-4">
                                      Assign "{examName}" Test to Class
                                  </div>
                                  <div className="w-full items-stretch">
                                    <div className="inline" >Class Code: </div>
                                    <input
                                        type="text"
                                        value={assignClassCode}
                                        onChange={(e) => setAssignClassCode(e.target.value)}
                                        className="border rounded-md p-1 m-1 inline"
                                        placeholder="Enter class code"
                                    />
                                  </div>
                              </div>
                              <div className="mt-5 sm:mt-6">
                                  <span className="flex w-full rounded-md shadow-sm">
                                      <button onClick={addTest} type="button" className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-blue-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                                          Assign Test
                                      </button>
                                      <button onClick={cancelAddTest} type="button" className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-pink-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                                          Cancel
                                      </button>
                                  </span>
                              </div>
                          </div>
                      </div>
                  </div>
              )}
              {deleteTestVisible && (
                  <div className="fixed z-10 inset-0 overflow-y-auto">
                      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                          <div className="fixed inset-0 transition-opacity">
                              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                          </div>
                          <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                              <div>
                                  <div className="text-lg leading-6 font-medium text-gray-900 mb-4">
                                      Delete Test "{examName}"
                                  </div>
                                  <div className="w-full items-stretch">
                                    <div className="inline" >Are you sure you want to delete "{examName}"?</div>
                                  </div>
                              </div>
                              <div className="mt-5 sm:mt-6">
                                  <span className="flex w-full rounded-md shadow-sm">
                                      <button onClick={deleteTest} type="button" className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-pink-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                                          Confirm Delete "{examName}"
                                      </button>
                                      <button onClick={hideDeleteTest} type="button" className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-blue-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                                          Cancel
                                      </button>
                                  </span>
                              </div>
                          </div>
                      </div>
                  </div>
              )}
        </div>
    )
}

export default TestItem
