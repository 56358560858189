import React, { useState, useEffect, useRef } from 'react'
import LoginForm from './LoginForm'
import StudentForm from './StudentForm'
import SignupForm from './SignupForm'
import UserInfo from './UserInfo'
import ChatRoom from './ChatRoom'
import ClassItem from './ClassItem'
import ConfirmSignUp from './ConfirmSignUp'
import FileInput from './FileInput'
import ContactPopup from './ContactPopup'
import ClassScreen from './ClassScreen'
import AssessmentScreen from './AssessmentScreen'
import OpenAIIcon from './OpenAIIcon'
import ForGenAI from './ForGenAI'
import Footer from './Footer'
import { checkLogin, logout, refreshToken, goToPaymentPage, setPaymentSuccessful } from './api'
import styles from './ColorCycle.module.css'

function App() {
  const [isStudentLoggedIn, setIsStudentLoggedIn] = useState(false)
  const [isTeacherLoggedIn, setIsTeacherLoggedIn] = useState(false)
  const [showSignUp, setShowSignUp] = useState(false)
  const [isSignedUp, setIsSignedUp] = useState(false)
  const [isSaved, setIsSaved] = useState(true)
  const [isConfirmed, setIsConfirmed] = useState(false)
  const [showSaveWarning, setShowSaveWarning] = useState(false)
  const [classIds, setClassIds] = useState([])
  const [examIds, setExamIds] = useState([])
  const [userInfo, setUserInfo] = useState(null)
  const [lastActivity, setLastActivity] = useState(Date.now())
  const refreshInterval = useRef(null)
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [isPaymentPopupOpen, setIsPaymentPopupOpen] = useState(false)
  const [paymentPopupText, setPaymentPopupText] = useState("")
  const [username, setUsername] = useState("")
  const [studentExamId, setStudentExamId] = useState("")
  const [studentExamName, setStudentExamName] = useState("")
  const [studentName, setStudentName] = useState("")
  const [studentId, setStudentId] = useState("")
  const [assessments, setAssessments] = useState([])
  const [isFree, setIsFree] = useState(true)

  useEffect(() => {
    const queryString = window.location.search
    const queryParams = new URLSearchParams(queryString)
    if (queryParams.has('p')) {
      const pValue = queryParams.get('p')
      console.log(`p = ${pValue}`)
      if (isPaymentPopupOpen) {
        return
      }
      if (pValue === '0') {
        setIsPaymentPopupOpen(true)
        setPaymentPopupText("Payment unsuccessful. Please contact info@forgen.ai for assistance.")
      } else {
        setPaymentSuccessful(pValue)
        setIsPaymentPopupOpen(true)
        setPaymentPopupText("Payment successful. Please login below to get started.")
      }
      queryParams.delete('p')
      window.history.replaceState(null, null, '?' + queryParams.toString())
    } else {
      console.log('payment key "p" is not present in the query parameters.')
    }
  }, [])

  useEffect(() => {
      // Global click event listener
      const updateLastActivity = () => {
          setLastActivity(Date.now())
      }
      window.addEventListener('click', updateLastActivity)
      // Start the refresh interval
      if (isTeacherLoggedIn){
        refreshInterval.current = setInterval(() => {
            if (Date.now() - lastActivity > 5 * 60 * 1000) { // 5 minutes inactivity
                clearInterval(refreshInterval.current)
            } else {
                refreshToken()
            }
        }, 60 * 3000) // Refresh every 1 minute
      }
      // Clean up on component unmount
      return () => {
          window.removeEventListener('click', updateLastActivity)
          if (refreshInterval.current) {
              clearInterval(refreshInterval.current)
          }
      }
    }, [lastActivity])

  useEffect(() => {
    localStorage.setItem('storedIsSaved', JSON.stringify(isSaved))
  }, [isSaved])

  const openPopup = () => {
    setIsPopupOpen(true)
  }

  const closePopup = () => {
    setIsPopupOpen(false)
  }

  const handleTeacherLogin = (userInfo) => {
      setIsTeacherLoggedIn(true)
      setUserInfo(userInfo)
      const classIds = userInfo.classes.map(cls => cls.class_id)
      setClassIds(classIds)
      const examIds = userInfo.exams.map(cls => cls.exam_id)
      setExamIds(examIds)
  }

  const doLogout = () => {
      setIsTeacherLoggedIn(false)
      setUserInfo(null)
      logout(true)
  }

  const handleLogout = () => {
    if (isSaved) {
      doLogout()
    } else {
      setShowSaveWarning(true)
    }
  }

  const goToPaymentWithUserName = (user_name) => {
    goToPaymentPage(user_name)
  }

  const goToPayment = () => {
    goToPaymentPage(username)
  }

  useEffect(() => {
//      if (!isStudentLoggedIn){
//          checkLogin().then(response => {
//            setIsTeacherLoggedIn(true)
//            const classIds = response.classes.map(cls => cls.class_id)
//            setClassIds(classIds)
//            const examIds = response.exams.map(cls => cls.exam_id)
//            setExamIds(examIds)
//          })
//          .catch(error => console.error('Error: ', error))
//         }
    }, [])

  function openContactPopup(){

  }

  const signUpSuccess = (user_name) => {
    setIsSignedUp(true)
    setUsername(user_name)
  }

  const handleSignUp = () => {
    setShowSignUp(true)
  }

  const addClassId = (newClassId) => {
      setClassIds(oldClassIds => [...oldClassIds, newClassId]);
  }

  const onStudentLogin = (results) => {
      if (results && results.exam_id){
        setStudentExamId(results.exam_id)
      }
      if (results && results.student_id){
        setStudentId(results.student_id)
      }
      if (results && results.student_name){
        setStudentName(results.student_name)
      }
      if (results && results.exam_name){
        setStudentExamName(results.exam_name)
      }
      if (results && results.assessments){
        setAssessments(results.assessments)
      }
      setIsStudentLoggedIn(true)
  }

  const removeAssessment = (assessment) => {
    const updatedAssessments = assessments.filter(an_assessment => an_assessment.assessment_id !== assessment.assessment_id)
    setAssessments(updatedAssessments)
  }

//          <div className="bg-pink-100 rounded inline-block">
//            <h1 className='p-3 transition-all ease-in-out duration-1000 text-4xl font-bold text-center'>
//            <span className={`${styles.textColorCycleReverse} font-bold`}>standard</span><span className={`${styles.textColorCycle} font-normal`}> assessments</span></h1>
////          </div>
//    <div className={`${styles.colorCycle} flex-col flex items-center justify-center h-screen`}>

  return (
    <div className="bg-[#056AF9] flex-col flex items-center justify-center h-screen">
      <div className="h-screen flex flex-col items-center justify-center mt-6">
        {!isStudentLoggedIn && !isTeacherLoggedIn && (
          <div className="m-2 py-8 md:w-[528px] rounded flex flex-col justify-center items-center bg-[linear-gradient(200deg,_#93c5fd_0%,_transparent_100%)]">
            <img src="scenarionaut_logo-thin.png" className="w-5/6"/>
          </div>
        )}
        {isTeacherLoggedIn && (
          <button onClick={handleLogout} className='bg-red-300 hover:bg-red-500 text-white font-bold py-2 px-4 rounded absolute bottom-2 sm:right-2 sm:top-2 sm:bottom-auto'>
            Logout
          </button>
        )}
        {isTeacherLoggedIn && (
          <div className="flex flex-row">
            <ClassScreen classIds={classIds} addClassIdToClassIds={addClassId} />
            <AssessmentScreen examIds={examIds} setExamIds={setExamIds} />
          </div>
        )}

        {isStudentLoggedIn && (
          <div>
            <ChatRoom onRemoveAssessment={removeAssessment} user={userInfo} isSaved={isSaved} setIsSaved={setIsSaved} examId={studentExamId} examName={studentExamName} studentId={studentId} studentName={studentName} assessments={assessments} />
          </div>
        )}
        {!isStudentLoggedIn && !isTeacherLoggedIn && (
          <div className='flex flex md:flex-row sm:flex-col items-center justify-center'>
            <div className='items-center md:flex justify-center'>
              <StudentForm onLogin={onStudentLogin} />
              {!showSignUp && (<LoginForm onLogin={handleTeacherLogin} goToPaymentSite={goToPaymentWithUserName} onSignUp={handleSignUp} />)}
              {showSignUp && !isSignedUp && !isConfirmed && (
                <>
                    <SignupForm onSignupSuccess={signUpSuccess} />
                </>
              )}
              {isSignedUp && !isConfirmed && (<ConfirmSignUp onConfirm={() => setIsConfirmed(true)} closeConfirmSignUpWindow={() => setIsSignedUp(false)} />)}
              {isConfirmed && (
                <div className="fixed m-0 z-10 top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
                  <div className="p-4 bg-white rounded-lg shadow-md">
                    <p className="mb-4">Success! Please complete payment to get started.</p>
                    <div className="flex items-center justify-center">
                      <button
                        onClick={goToPayment}
                        className="bg-blue-500 text-white p-2 rounded"
                      >
                        Pay with Stripe
                      </button>
                    </div>
                  </div>
                </div>
              )}
              </div>
          </div>
        )}
      </div>
      {!isStudentLoggedIn && !isTeacherLoggedIn && (<>
        <OpenAIIcon />
        <ForGenAI />
      </>)}
      <ContactPopup isOpen={isPopupOpen} close={closePopup} />
      <Footer openPopup={openPopup} />
      {showSaveWarning && (
        <div className="fixed m-0 z-10 top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
          <div className="p-4 bg-white rounded-lg shadow-md">
            <p className="mb-4">Current chat will be lost if unsaved. Make sure to save first.</p>
            <div className="flex space-x-4">
              <button
                onClick={doLogout}
                className="bg-red-500 text-white p-2 rounded"
              >
                Logout Without Saving
              </button>
              <button
                onClick={() => setShowSaveWarning(false)}
                className="bg-blue-500 text-white p-2 rounded"
              >
                Go Back to Save
              </button>
            </div>
          </div>
        </div>
      )}
      {isPaymentPopupOpen && (
        <div className="fixed m-0 z-10 top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
          <div className="p-4 bg-white rounded-lg shadow-md">
            <p className="mb-4">{paymentPopupText}</p>
            <div className="flex space-x-4 items-center justify-center">
              <button
                onClick={() => setIsPaymentPopupOpen(false)}
                className="bg-blue-500 text-white p-2 rounded"
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default App
