import React, { useState } from 'react'
import { getFeedbackReport } from './api'
import RedX from './RedX'
import jsPDF from 'jspdf'

function GradeTable({ data, roster, examId, student_grades }) {
  const [messagesVisible, setMessagesVisible] = useState(false)
  const [messagesForDisplay, setMessagesForDisplay] = useState([])
  const [studentSelected, setStudentSelected] = useState({})
  const [selectedStandardForMessages, setSelectedStandardForMessages] = useState({})

  const students = data
  const viewMessages = (messages, studentSelected) => {
    if (messages && messages.length > 0){
      setMessagesVisible(true)
      setMessagesForDisplay(messages)
      setSelectedStandardForMessages(studentSelected.standard_code)
      setStudentSelected(studentSelected)
    }
  }

  const hideMessages = () => {
    setMessagesVisible(false)
  }

  const downloadPDF = () => {
    const doc = new jsPDF()
    let yPosition = 10

    const userStyle = { align: "right", maxWidth: 180 }
    const botStyle = { align: "left", maxWidth: 180 }

    messagesForDisplay.forEach(message => {
      let lines = doc.splitTextToSize(message.content, message.role === "user" ? userStyle.maxWidth : botStyle.maxWidth)
      let lineHeight = 7
      let blockHeight = lines.length * lineHeight

      if (message.role === "user") {
        doc.text(lines, doc.internal.pageSize.width - 10, yPosition, userStyle)
      } else {
        doc.text(lines, 10, yPosition, botStyle)
      }

      yPosition += blockHeight + 5
    })
    let filename = "conversation.pdf"
    if (studentSelected && studentSelected["student_name"]) {
      filename = studentSelected["student_name"] + ".pdf"
    }
    doc.save(filename)
  }

  const downloadReportPDF = (report, studentSelected) => {
        const doc = new jsPDF()
        let yPosition = 10
        const lineHeight = 7
        const maxWidth = 180
        const pageHeight = doc.internal.pageSize.height

        let lines = doc.splitTextToSize(report, maxWidth)

        lines.forEach(line => {
          if (yPosition > pageHeight - 10) {
            doc.addPage()
            yPosition = 10
          }
          doc.text(line, 10, yPosition)
          yPosition += lineHeight
        })

        let filename = "Feedback for " + studentSelected['student_name'] + " - " + examId + ".pdf"
        doc.save(filename)
      }

  const getFeedbackReportForStudent = (studentId) => {
   getFeedbackReport(examId, studentSelected["student_id"]).then((getFeedbackResponse) => {
          if (getFeedbackResponse && getFeedbackResponse["report"]){
            downloadReportPDF(getFeedbackResponse["report"], studentSelected)
            return
          }
        })
  }

  const assessmentIds = Object.keys(student_grades)
  const studentIds = [...new Set(assessmentIds.flatMap(assessmentId => Object.keys(student_grades[assessmentId])))]

  const getGradeData = (assessmentId, studentId) => {
    return student_grades[assessmentId][studentId] || null
  }
   const getStudentName = (studentId) => {
    for (const assessmentId of assessmentIds) {
      if (student_grades[assessmentId][studentId]) {
        return student_grades[assessmentId][studentId].student_name
      }
    }
    return 'Unknown Student'
  }

  return (
    <>
      <div className="overflow-x-auto overflow-y-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Student</th>
              {assessmentIds.map(assessmentId => (
                <th key={assessmentId} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {student_grades[assessmentId][studentIds[0]]?.standard_code || 'Assessment'}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {studentIds.map(studentId => (
              <tr key={studentId}>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  {getStudentName(studentId)}
                </td>
                {assessmentIds.map(assessmentId => {
                  const gradeData = getGradeData(assessmentId, studentId)
                  return (
                    <td key={assessmentId} className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {gradeData ? `Grade: ${gradeData.overall_grade} (${gradeData.grade_string})` : 'N/A'}
                      {gradeData && gradeData.messages && gradeData.messages.length > 0 && (
                        <button
                          onClick={() => viewMessages(gradeData.messages, gradeData)}
                          className="ml-4 px-4 py-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                        >
                          View Messages
                        </button>
                      )}
                    </td>
                  )
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>


      {messagesVisible && (
          <div className="fixed inset-0 z-10 flex items-center justify-center bg-black bg-opacity-50">
            <div className="relative max-w-lg w-full">
              <button
                onClick={hideMessages}
                className="absolute top-0 right-0 bg-white p-2 rounded-full hover:shadow-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                aria-label="Close"
              >
                <RedX />
              </button>
              <button
                onClick={downloadPDF}
                className="absolute top-0 left-0 bg-white p-2 rounded-full hover:shadow-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                aria-label="Close"
              >
                Download PDF
              </button>
              <button
                onClick={() => getFeedbackReportForStudent()}
                className="absolute top-0 bg-white p-2 rounded-full hover:shadow-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                aria-label="Close"
              >
                Download Feedback Report
              </button>
              <div className="bg-white p-4 rounded overflow-y-auto mt-24" style={{ maxHeight: '75vh' }}>
                <div className="text-black font-bold pb-1">
                  {studentSelected.student_name} - {selectedStandardForMessages}
                </div>
                {messagesForDisplay.map((message, index) => (
                  <div key={index} className={`mb-4 p-3 rounded shadow ${message.role === 'user' ? 'bg-blue-100' : 'bg-green-100'}`}>
                    <p className="text-sm text-gray-700 break-words">{message.content}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )
      }
    </>
  )
}

export default GradeTable
