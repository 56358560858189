import React from 'react'

function Spinner() {
  return (
    <div className='inline-flex items-center justify-center ml-1 mr-1'>
      <div className='inline-block h-4 w-4 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-primary motion-reduce:animate-[spin_1.5s_linear_infinite] text-blue-500'></div>
    </div>
  )
}

export default Spinner
